import { graphql } from 'gatsby';
import { Wrapper, Button, Content, StyledChevron } from './Collapsible.styles';
import RichText from '../RichText/RichText';

interface Props {
  content: Queries.CollapsibleFragment['elements']['content'];
  title: Queries.CollapsibleFragment['elements']['title']['value'];
}

const Collapsible = ({ content, title }: Props) => (
  <Wrapper>
    <Button role="button">
      <StyledChevron />
      {title}
    </Button>
    <Content>
      <RichText {...content} />
    </Content>
  </Wrapper>
);

export const fragment = graphql`
  fragment Collapsible on kontent_item_collapsible {
    __typename
    elements {
      title {
        value
      }
      content {
        images {
          description
          height
          url
          image_id
          width
        }
        links {
          url_slug
          type
          link_id
          codename
        }
        value
        modular_content {
          ... on kontent_item {
            system {
              codename
              type
            }
          }
          ...AnchorHeading
          ...Callout
          ...CodeSnippet
          ...Iframe
          ...Video
          ...Image
          ...Table
        }
      }
    }
  }
`;

export default Collapsible;
