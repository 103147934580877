import { graphql } from 'gatsby';
import RichText from '@components/RichText/RichText';
import { BannerWrapper, BannerContent, BannerCTA } from './Banner.styles';

const Banner = ({ elements }: Queries.BannerFragment) => {
  const { text, cta, variant } = elements;
  const hasCTA = !!cta?.value;

  return (
    <BannerWrapper variant={variant.value}>
      <BannerContent>
        <RichText value={text.value} />
      </BannerContent>
      {hasCTA && <BannerCTA {...cta.value} />}
    </BannerWrapper>
  );
};

export const fragment = graphql`
  fragment Banner on kontent_item_banner {
    __typename
    elements {
      text {
        value
      }
      cta {
        value {
          ...Link
        }
      }
      variant {
        value
      }
    }
  }
`;

export default Banner;
