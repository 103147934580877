import { graphql } from 'gatsby';
import RichText from '@components/RichText/RichText';
import {
  TabButtonWrapper,
  TabWrapper,
  TabContentWrapper,
  StyledTabButton,
  TabIcon,
} from './Tabs.styles';
import { useLocation } from '@reach/router';
import slugify from '@utils/slugify';
import { useEffect, useState } from 'react';

const TabButton = ({
  tab,
  isActive,
  onSelect,
}: {
  tab: Queries.TabsFragment['elements']['tabs']['value'][number];
  isActive: boolean;
  onSelect: (tabId: Queries.TabsFragment['elements']['tabs']['value'][number]['id']) => void;
}) => {
  const title = tab.elements.title?.value?.toUpperCase();
  const icon = tab.elements.icon?.value?.[0];
  const id = tab.id;
  const hash = tab.elements.url_slug?.value || slugify(tab.elements.title.value);

  return (
    <StyledTabButton
      onClick={() => {
        if (typeof window !== 'undefined') {
          window.history.pushState({}, '', `#${hash}`);
        }
        onSelect(tab.id);
      }}
      isActiveTab={isActive}
      role="tab"
      id={hash}
      aria-controls={`tabpanel-${id}`}
      aria-selected={isActive}
    >
      {icon && <TabIcon src={icon.url} alt={icon.description ?? undefined} />}
      {title && <h3>{title}</h3>}
    </StyledTabButton>
  );
};

const TabContent = ({
  tab,
  isActive,
}: {
  tab: Queries.TabsFragment['elements']['tabs']['value'][number];
  isActive: boolean;
}) => {
  const tabDescription = tab.elements.description;
  const id = tab.id;
  const hash = tab.elements.url_slug?.value || slugify(tab.elements.title.value);

  return (
    <TabContentWrapper
      isActiveTab={isActive}
      id={`tabpanel-${id}`}
      role="tabpanel"
      aria-labelledby={hash}
    >
      <RichText {...tabDescription} />
    </TabContentWrapper>
  );
};

const Tabs = ({ tabs }: { tabs: Queries.TabsFragment['elements']['tabs'] }) => {
  const { hash } = useLocation();
  const [activeTab, setActiveTab] = useState(tabs.value[0].id);

  useEffect(() => {
    const newTab = tabs.value.find((tab) => {
      const tabHash = tab.elements.url_slug?.value || slugify(tab.elements.title.value);
      return tabHash === hash.replace('#', '');
    });

    if (newTab?.id) {
      setActiveTab(newTab.id);
    }
  }, [tabs, hash]);

  return (
    <TabWrapper>
      <TabButtonWrapper role="tablist">
        {tabs.value.map((tab) => (
          <TabButton
            tab={tab}
            key={tab.id}
            isActive={activeTab === tab.id}
            onSelect={setActiveTab}
          />
        ))}
      </TabButtonWrapper>
      {tabs.value.map((tab) => (
        <TabContent tab={tab} key={tab.id} isActive={activeTab === tab.id} />
      ))}
    </TabWrapper>
  );
};

export default Tabs;

export const fragment = graphql`
  fragment Tabs on kontent_item_tabs {
    __typename
    elements {
      tabs {
        value {
          elements {
            url_slug {
              value
            }
            description {
              images {
                description
                height
                url
                image_id
                width
              }
              links {
                url_slug
                type
                link_id
                codename
              }
              value
              modular_content {
                ... on kontent_item {
                  system {
                    codename
                    type
                  }
                }
                ...AnchorHeading
                ...Callout
                ...CodeSnippet
                ...Collapsible
                ...Iframe
                ...Video
                ...Image
                ...Table
                ... on kontent_item_shared_content {
                  __typename
                  elements {
                    content {
                      value
                      images {
                        description
                        height
                        url
                        width
                      }
                      links {
                        codename
                        link_id
                        type
                        url_slug
                      }
                      modular_content {
                        ... on kontent_item {
                          system {
                            codename
                            type
                          }
                        }
                        ...AnchorHeading
                        ...Callout
                        ...CodeSnippet
                        ...Collapsible
                        ...Iframe
                        ...Video
                        ...Image
                        ...Table
                        ... on kontent_item_shared_content {
                          __typename
                          elements {
                            content {
                              value
                              images {
                                description
                                height
                                url
                                width
                              }
                              links {
                                codename
                                link_id
                                type
                                url_slug
                              }
                              modular_content {
                                ... on kontent_item {
                                  system {
                                    codename
                                    type
                                  }
                                }
                                ...AnchorHeading
                                ...Callout
                                ...CodeSnippet
                                ...Collapsible
                                ...Iframe
                                ...Video
                                ...Image
                                ...Table
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            icon {
              value {
                url
                description
              }
            }
            title {
              value
            }
          }
          id
        }
      }
    }
  }
`;
