import tw, { css, styled } from 'twin.macro';

const iconSize = '28px';

export const Wrapper = styled.div<{
  variant: Queries.kontent_item_callout_elements_variant_enum;
}>`
  ${tw`border border-solid`}
  ${tw`mb-8 pl-24 pr-6 pt-6`}
  ${tw`relative`}
  ${tw`before:absolute before:left-[17px] before:top-[19px] `}
  ${tw`before:w-[${iconSize}] before:h-[${iconSize}] before:bg-no-repeat before:[background-size: ${iconSize}]`}

  ${({ variant }) =>
    variant === 'info' &&
    css`
      ${tw`bg-info-light`}
      ${tw`border-info`}
      ${tw`before:bg-infoIcon`}
    `}
  ${({ variant }) =>
    variant === 'warning' &&
    css`
      ${tw`bg-warning-light`}
      ${tw`border-warning`}
      ${tw`before:bg-warningIcon`}
    `}
  ${({ variant }) =>
    variant === 'success' &&
    css`
      ${tw`bg-success-light`}
      ${tw`border-success`}
      ${tw`before:bg-successIcon`}
    `}
  ${({ variant }) =>
    variant === 'danger' &&
    css`
      ${tw`bg-danger-light`}
      ${tw`border-danger`}
      ${tw`before:bg-dangerIcon`}
    `}
`;

export const CalloutMessage = styled.div`
  ${tw`all-child:last:mb-0`}
  ${tw`mb-6`}

  code {
    ${tw`text-base`}
  }
`;
