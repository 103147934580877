import { graphql } from 'gatsby';
import tw from 'twin.macro';

export const getTextColor = (color: Queries.kontent_snippet_theme_theme__color_enum) => {
  switch (color) {
    case 'red':
      return tw`text-primary-red`;
    case 'blue':
      return tw`text-secondary-s2-blue`;
  }
};

export const getBackgroundColor = (color?: Queries.kontent_snippet_theme_theme__color_enum) => {
  switch (color) {
    case 'red':
      return tw`bg-light-linear-red-grad-vertical`;
    case 'blue':
      return tw`bg-light-linear-blue-grad-vertical`;
  }
};

export const fragment = graphql`
  fragment Theme on kontent_snippet_theme {
    theme__color {
      value
    }
  }
`;
