import tw, { css } from 'twin.macro';
import 'react-toastify/dist/ReactToastify.css';
const tailwindConfig = require('../../tailwind.config');

const globalStyles = css`
  @font-face {
    font-family: 'Saira-Fallback';
    size-adjust: 104.18%;
    src: local('Arial');
  }

  @font-face {
    font-family: 'Maven-Pro-Fallback';
    size-adjust: 103.25%;
    src: local('Arial');
  }

  @font-face {
    font-family: 'Saira';
    font-style: normal;
    font-weight: 400;
    src:
      url('/fonts/saira-variable.woff2') format('woff2 supports variations'),
      url('/fonts/saira-variable.woff2') format('woff2-variations');
    font-weight: 100 900;
    text-rendering: optimizeLegibility;
    font-display: swap;
  }

  @font-face {
    font-family: 'Maven Pro';
    font-style: normal;
    font-weight: 400;
    src:
      url('/fonts/maven-pro-variable.woff2') format('woff2 supports variations'),
      url('/fonts/maven-pro-variable.woff2') format('woff2-variations');
    font-weight: 400 900;
    text-rendering: optimizeLegibility;
    font-display: swap;
  }

  html {
    font-size: 62.5%; /* 62.5% of 16px = 10px */
  }

  body {
    background-color: white;
    font-size: 1.8rem; /* 1.8 * 10px = 18px */
    ${tw`font-maven-pro antialiased`}
    -webkit-tap-highlight-color: transparent;
  }

  :root {
    --toastify-color-success: ${tailwindConfig.theme.colors.primary.purple};
    --toastify-toast-width: 448px;
    --toastify-font-family: 'Maven Pro', sans-serif;
  }
  .Toastify__toast {
    ${tw`p-[15px]`}
  }
  .Toastify__toast-body {
    ${tw`font-medium`}
    ${tw`pb-[20px]`}
  }
  .Toastify__progress-bar {
    ${tw`px-[20px]`}
    ${tw`w-[calc(100%-40px)]`}
    ${tw`rounded-none`}
  }
  .Toastify__close-button {
    ${tw`opacity-100`}
  }
  .Toastify__progress-bar--wrp {
    ${tw`bottom-[15px]`}
    ${tw`left-[15px]`}
    ${tw`w-[calc(100%-30px)]`}
    ${tw`rounded-none`}
    margin-y: 20px;
  }
  .Toastify__progress-bar--bg {
    ${tw`!bg-[transparent]`}
  }
  .Toastify__progress-bar {
    ${tw`mx-0`}
    ${tw`opacity-100`}
  }
  .Toastify__toast--success {
    .Toastify__progress-bar--wrp {
      background: rgba(101, 209, 255, 0.6);
    }
    .Toastify__progress-bar--success {
      ${tw`bg-secondary-s1-blue`}
    }
    .Toastify__close-button {
      ${tw`text-neutrals-n1`}
    }
  }
`;

export default globalStyles;
