import tw, { styled } from 'twin.macro';
import { ImageElement } from '@kontent-ai/gatsby-components';

export const StyledImage = styled(ImageElement)`
  ${tw`h-auto`}
`;

export const StyledFigcaption = styled.figcaption`
  ${tw`mt-2 mb-6`}
  ${tw`text-neutrals-n6 text-sm`}
  ${tw`font-medium`}
`;

export const StyledFigure = styled.figure<{
  $align: Queries.ImageFragment['elements']['alignment']['value'];
  $width: Queries.ImageFragment['elements']['width']['value'];
}>`
  [data-gatsby-image-wrapper] {
    ${tw`block`}
  }
  ${StyledFigcaption} {
    ${({ $align }) => {
      switch ($align) {
        case 'left':
          return tw`text-left`;
        case 'right':
          return tw`text-right`;
        case 'center':
          return tw`text-center`;
      }
    }}
  }
  ${StyledImage} {
    ${({ $width }) => {
      switch ($width) {
        case 'percent_100':
          return tw`w-full`;
        case 'percent_75':
          return tw`w-9/12`;
        case 'percent_50':
          return tw`w-6/12`;
        case 'percent_25':
          return tw`w-3/12`;
        case 'auto':
          return tw`w-auto`;
      }
    }}
    ${({ $align }) => {
      switch ($align) {
        case 'left':
          return tw`ml-0 mr-auto`;
        case 'right':
          return tw`ml-auto mr-0`;
        case 'center':
          return tw`ml-auto mr-auto`;
      }
    }}
  }
`;
