import React, { useEffect } from 'react';

const useClickOutside = (
  ref: React.MutableRefObject<HTMLElement | null>,
  callback: () => void
): void => {
  const handleClick = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useClickOutside;
