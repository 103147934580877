import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`relative mb-9 pb-6`}
  ${tw`border-borderColor border`}
`;

export const LoadingPlaceholder = styled.div`
  ${tw`h-[198px] mb-9`}
  ${tw`bg-neutrals-n2 border-borderColor border`}
`;

export const ScannerHeader = styled.div`
  ${tw`flex p-6`}
  ${tw`border-b border-b-borderColor bg-neutrals-n2`}
`;

export const ScannerHeaderTitle = styled.span`
  ${tw`font-medium`}
  ${tw`text-primary-purple text-sm`}
`;

export const ScannerHeaderItem = styled.span`
  ${tw`text-sm`}
  &:not(:first-of-type) {
    ${tw`border-l border-l-borderColor ml-6 pl-6`}
  }
`;

export const ShowMoreButton = styled.button<{ expanded: boolean }>`
  ${tw`text-link text-sm font-medium hover:text-link-hover`}
  ${tw`absolute right-6 top-[15px]`}
  svg {
    ${tw`text-neutrals-n7`}
    ${tw`inline`}
    ${tw`w-[24px] h-[24px] ml-2`}
    ${({ expanded }) => (expanded ? tw`transform rotate-180` : tw`transform rotate-0`)}
  }
`;

export const StyledLink = styled.a`
  ${tw`text-link text-sm font-medium hover:text-link-hover`}
`;
