import { MenuItemFirstLevel } from '@components/Menu/Menu.styles';
import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';

export const StyledLink = styled(Link)(() => [tw`block`]);

export const StyledLinkText = styled.span(
  ({ isActive, isFirstLevel }: { isActive: boolean; isFirstLevel: boolean }) => [
    isActive && tw`text-link font-medium`,
    !isActive && tw`font-normal`,
    isFirstLevel && MenuItemFirstLevel,
    !isFirstLevel && tw`pl-5 py-2 text-xs`,
    tw`block`,
    tw`text-left`,
    tw`hover:text-link-hover hover:font-medium hover:bg-white`,
  ]
);
