import { SerializedStyles } from '@emotion/utils';
import tw, { TwStyle, css, styled } from 'twin.macro';

export const TableWrapper = styled.div<{
  alignment: Queries.kontent_item_table_elements_alignment_enum;
  bodyTextAlignment: Queries.kontent_item_table_elements_body_text_alignment_enum;
  bodyTextVerticalAlignment: Queries.kontent_item_table_elements_body_text_vertical_alignment_enum;
  headerTextAlignment: Queries.kontent_item_table_elements_header_text_alignment_enum;
  headerTextWrap: Queries.kontent_item_table_elements_header_text_wrap_enum;
  showBorders: Queries.kontent_item_table_elements_show_borders_enum;
  showHeaderBackground: Queries.kontent_item_table_elements_show_header_background_enum;
  $width: Queries.kontent_item_table_elements_width_enum;
}>`
  ${tw`flex`}
  ${tw`mb-6`}
  ${({ alignment }) => alignments[alignment]}
  table {
    ${({ showBorders }) => bordersStyle[showBorders]}
    ${({ $width }) => tableWidths[$width]}
    td {
      ${({ bodyTextAlignment }) => bodyTextAlignments[bodyTextAlignment]}
      ${({ bodyTextVerticalAlignment }) => bodyTextVerticalAlignments[bodyTextVerticalAlignment]}
    }
    th {
      ${({ showHeaderBackground }) => headerBackgroundStyle[showHeaderBackground]}
      ${({ headerTextAlignment }) => headerTextAlignments[headerTextAlignment]}
      ${({ headerTextWrap }) => headerTextWraps[headerTextWrap]}
    }
    td,
    th {
      ${tw`py-[5px] px-[10px]`}
      ${({ showBorders }) => bordersStyle[showBorders]}
    }
  }
`;

const alignments: Record<Queries.kontent_item_table_elements_alignment_enum, TwStyle> = {
  left: tw`justify-start`,
  center: tw`justify-center`,
  right: tw`justify-end`,
};

const bodyTextAlignments: Record<
  Queries.kontent_item_table_elements_body_text_alignment_enum,
  TwStyle
> = {
  left: tw`text-left`,
  center: tw`text-center`,
  right: tw`text-right`,
};

const bodyTextVerticalAlignments: Record<
  Queries.kontent_item_table_elements_body_text_vertical_alignment_enum,
  TwStyle
> = {
  bottom: tw`align-bottom`,
  center: tw`align-middle`,
  top: tw`align-top`,
};

const bordersStyle: Record<Queries.kontent_item_table_elements_show_borders_enum, TwStyle> = {
  yes: tw`border border-solid border-grey border-collapse`,
  no: tw`border-0`,
};

const headerBackgroundStyle: Record<
  Queries.kontent_item_table_elements_show_header_background_enum,
  TwStyle
> = {
  yes: tw`bg-grey-light`,
  no: tw`bg-utils-transparent`,
};

const headerTextAlignments: Record<
  Queries.kontent_item_table_elements_header_text_alignment_enum,
  TwStyle
> = {
  left: tw`text-left`,
  center: tw`text-center`,
  right: tw`text-right`,
};

const headerTextWraps: Record<
  Queries.kontent_item_table_elements_header_text_wrap_enum,
  TwStyle | SerializedStyles
> = {
  no_wrap: tw`whitespace-nowrap`,
  wrap_all: tw`break-all`,
  wrap_words: css`
    code {
      word-break: keep-all;
    }
    ${tw`break-words`}
  `,
};

const tableWidths: Record<Queries.kontent_item_table_elements_width_enum, TwStyle> = {
  percent_100: tw`w-full`,
  percent_75: tw`w-3/4`,
  percent_50: tw`w-1/2`,
  percent_25: tw`w-1/4`,
  auto: tw`w-auto`,
};
