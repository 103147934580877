import RoundedCrossSvg from '../../../../../static/images/rounded-cross.svg';
import tw, { styled } from 'twin.macro';

export const InputWrapper = styled.div`
  ${tw`relative`}
`;

export const StyledInput = styled.input`
  ${tw`mb-3`}
  ${tw`w-full`}
  ${tw`px-4 py-2`}
  ${tw`border border-solid border-borderColor [border-radius: 6px]`}
  ${tw`focus:border-primary-purple`}
  ${tw`placeholder:text-sm`}
  ${tw`focus:border-borderColor`}
`;

export const ResetButton = styled.button`
  ${tw`absolute`}
  ${tw`right-[12px] top-[12px]`}
`;

export const RoundedCross = tw(RoundedCrossSvg)`w-[16px] fill-neutrals-n6 hover:fill-neutrals-n7`;
