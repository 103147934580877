import tw, { styled } from 'twin.macro';
import Link from '@components/Link/Link';
import { ImageElement } from '@kontent-ai/gatsby-components';
import { getTextColor } from '@utils/theme';
import { type ButtonVariant, variants } from '@components/homepage/Link/Link.styles';

export const StyledSection = styled.section<{ hideOnMobile: boolean }>`
  ${tw`container`}
  ${tw`px-6 lg:px-0`}
  ${({ hideOnMobile }) => hideOnMobile && tw`hidden md:block`}
`;

export const StyledContainer = styled.div<{
  align: Queries.kontent_item_section_introduction_elements_text_alignment_enum;
}>`
  ${tw`grid grid-cols-12`}
  ${({ align }) => {
    if (align === 'left') return tw`text-left`;
    if (align === 'center') return tw`text-center`;
  }}
}
`;

export const StyledTextContainer = styled.div<{
  isReversed: boolean;
  hasImage: boolean;
  align: Queries.kontent_item_section_introduction_elements_text_alignment_enum;
}>`
  ${tw`col-start-1 col-end-13`}
  ${({ isReversed, hasImage, align }) => {
    if (hasImage) {
      return isReversed
        ? tw`lg:col-start-8 lg:col-end-13 lg:order-2`
        : tw`lg:col-start-1 lg:col-end-6 lg:order-1`;
    } else {
      if (align === 'left') return tw`lg:col-start-1 lg:col-end-9`;
      if (align === 'center') return tw`lg:col-start-3 lg:col-end-11`;
    }
  }}
`;

export const StyledImageContainer = styled.div<{ isReversed: boolean }>`
  ${tw`mt-5 lg:mt-0`}
  ${tw`col-start-1 col-end-13`}
  ${({ isReversed }) =>
    isReversed
      ? tw`lg:col-start-1 lg:col-end-7 lg:order-1`
      : tw`lg:col-start-7 lg:col-end-13 lg:order-2`}
`;

export const StyledEyebrow = styled.span<{
  color: Queries.kontent_snippet_theme_theme__color_enum;
}>`
  ${tw`block`}
  ${tw`mb-2`}
  ${tw`font-saira font-bold`}
  ${tw`text-sm uppercase`}
  ${({ color }) => getTextColor(color)}
`;

export const StyledHeadline = styled.h2<{
  size: Queries.kontent_item_section_introduction_elements_headline_size_enum;
}>`
  ${tw`block`}
  ${tw`font-saira font-semibold`}
  ${tw`text-primary-purple`}
  ${tw`scroll-mt-[100px]`}

  ${({ size }) => {
    switch (size) {
      case 'large':
        return [tw`text-lg`, tw`mb-5 lg:mb-3`];
      case 'medium':
        return [tw`text-md lg:text-lg`, tw`mb-3 lg:mb-[20px]`];
      case 'small':
        return [tw`text-m lg:text-md`, tw`mb-[20px] lg:mb-2`];
    }
  }}
`;

export const StyledDescription = styled.div`
  ${tw`block`}
  ${tw`font-maven-pro`}
  ${tw`text-sm text-textColor`}

  section {
    ${tw`pb-0`}
  }
`;

export const StyledButton = styled(Link)<{ variant: ButtonVariant }>`
  ${tw`mt-16`}
  ${({ variant }) => variant && variants[variant]}
`;

export const StyledImage = styled(ImageElement, {
  shouldForwardProp: (prop) => !['hasShadow'].includes(prop),
})<{
  hasShadow: boolean;
}>`
  ${({ hasShadow }) => hasShadow && tw`shadow-medium`}
`;
