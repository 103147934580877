import AnchorHeading from '../../components/AnchorHeading/AnchorHeading';
import Callout from '../../components/Callout/Callout';
import CodeSnippet from '../../components/CodeSnippet/CodeSnippet';
import Iframe from '../../components/Iframe/Iframe';
import Collapsible from '../../components/Collapsible/Collapsible';
import SonarScanner from '../../components/SonarScanner/SonarScanner';
import Tabs from '@components/Tabs/Tabs';
import Table from '@components/Table/Table';
import SharedContent from '@components/SharedContent/SharedContent';
import Image from '@components/Image/Image';
import Video from '@components/Video/Video';
import Link from '@components/Link/Link';
import Header from '@components/homepage/Header/Header';
import Footer from '@components/homepage/Footer/Footer';
import CTABlock from '@components/homepage/CTABlock/CTABlock';
import HeroBlock from '@components/homepage/HeroBlock/HeroBlock';
import SectionIntroduction from '@components/homepage/SectionIntroduction/SectionIntroduction';
import ColumnBlock from '@components/homepage/ColumnBlock/ColumnBlock';

type ResolveLinkedItemsProps =
  | (
      | Queries.CodeSnippetFragment
      | Queries.AnchorHeadingFragment
      | Queries.CalloutFragment
      | Queries.IframeFragment
      | Queries.CollapsibleFragment
      | Queries.SonarScannerFragment
      | Queries.TabsFragment
      | Queries.SharedContentFragment
      | Queries.ImageFragment
      | Queries.TableFragment
      | Queries.VideoFragment
      | Queries.LinkFragment
      | Queries.HeroBlockFragment
      | Queries.CTABlockFragment
      | Queries.SectionIntroductionFragment
      | Queries.HeaderHomepageFragment
      | Queries.FooterHomepageFragment
      | Queries.ColumnBlockFragment
    )
  | null;

const resolveLinkedItem = (item: ResolveLinkedItemsProps) => {
  if (!item) {
    return null;
  }

  switch (item.__typename) {
    case 'kontent_item_code_snippet':
      return <CodeSnippet {...item} />;
    case 'kontent_item_anchor_heading':
      const { heading, title: anchorTitle, url_slug } = item.elements;
      return (
        <AnchorHeading heading={heading.value} title={anchorTitle.value} urlSlug={url_slug.value} />
      );
    case 'kontent_item_callout':
      const { message, variant } = item.elements;
      return <Callout message={message} variant={variant.value} />;
    case 'kontent_item_iframe':
      const { height_css: heightCss, title: iframeTitle, url: iframeUrl } = item.elements;
      return <Iframe heightCss={heightCss} title={iframeTitle.value} url={iframeUrl.value} />;
    case 'kontent_item_collapsible':
      const { title: collapsibleTitle, content } = item.elements;
      return <Collapsible content={content} title={collapsibleTitle.value} />;
    case 'kontent_item_sonarscanner':
      const { build_system } = item.elements;
      return <SonarScanner buildSystem={build_system.value} />;
    case 'kontent_item_tabs':
      const {
        elements: { tabs },
      } = item;
      return <Tabs tabs={tabs} />;
    case 'kontent_item_table':
      return <Table elements={item.elements} />;
    case 'kontent_item_shared_content':
      return <SharedContent content={item.elements.content} />;
    case 'kontent_item_image':
      return <Image {...item} />;
    case 'kontent_item_video':
      return <Video {...item} />;
    case 'kontent_item_link':
      return <Link {...item} />;
    case 'kontent_item_header_homepage':
      return <Header {...item} />;
    case 'kontent_item_footer_homepage':
      return <Footer {...item} />;
    case 'kontent_item_hero_block':
      return <HeroBlock {...item} />;
    case 'kontent_item_section_introduction':
      return <SectionIntroduction {...item} />;
    case 'kontent_item_cta_block':
      return <CTABlock {...item} />;
    case 'kontent_item_column_block':
      return <ColumnBlock {...item} />;
    default:
      return null;
  }
};

export default resolveLinkedItem;
