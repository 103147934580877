import RichText from '@components/RichText/RichText';
import { FooterWrapper, IconWrapper } from './Footer.styles';
import isRichTextValid from '../../utils/kontent/isRichTextValid';

const Footer = ({ richTextValue }: { richTextValue?: string }) => {
  if (!richTextValue || !isRichTextValid(richTextValue)) {
    return null;
  }

  return (
    <FooterWrapper>
      <RichText value={richTextValue} />
      <IconWrapper>
        <a
          href="https://creativecommons.org/licenses/by-nc/3.0/us/"
          rel="noreferrer"
          target="_blank"
          title="Creative Commons License"
        >
          <img
            alt="Creative Commons License"
            height={31}
            src="/images/creative-commons.png"
            width={88}
          />
        </a>
      </IconWrapper>
    </FooterWrapper>
  );
};

export default Footer;
