import { PRODUCTS } from '@constants/global';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

export type ProductItem = {
  name: PRODUCTS;
  logo: Queries.ProductDataQuery['allKontentItemProduct']['nodes'][number]['elements']['logo'];
  logoTitle: Queries.ProductDataQuery['allKontentItemProduct']['nodes'][number]['elements']['logo_title'];
};

const useProducts = () => {
  const { allKontentItemProduct, allKenticoLink } = useStaticQuery<Queries.ProductDataQuery>(
    graphql`
      query ProductData {
        allKontentItemProduct {
          nodes {
            elements {
              logo {
                value {
                  url
                  description
                }
              }
              logo_title {
                value
              }
            }
            system {
              codename
            }
          }
        }
        allKenticoLink {
          nodes {
            codename
            pageId
            isHomepage
            product
            url
            versionId
          }
        }
      }
    `
  );

  return useMemo(() => {
    const internalLinks = allKenticoLink.nodes;
    const productNodes = allKontentItemProduct.nodes;

    const products = productNodes.reduce((list: ProductItem[], product) => {
      const { system, elements } = product;
      list.push({
        name: system.codename as PRODUCTS,
        logo: elements.logo,
        logoTitle: elements.logo_title,
      });

      return list;
    }, []);

    function getProductHomepageUrl(product: string) {
      return (
        internalLinks.find(
          (internalLink) => internalLink.isHomepage && internalLink.product === product
        )?.url || '/'
      );
    }

    return {
      getProductHomepageUrl,
      products,
    };
  }, [allKontentItemProduct.nodes, allKenticoLink.nodes]);
};

export default useProducts;
