import { graphql, useStaticQuery } from 'gatsby';
import { LATEST_SLUG } from '@constants/global';
import { useMemo } from 'react';

const useGlobalData = () => {
  const { allKenticoLink, allKontentItemLink } = useStaticQuery<Queries.NavigationDataQuery>(
    graphql`
      query NavigationData {
        allKenticoLink {
          nodes {
            codename
            pageId
            isHomepage
            title
            url
            versionId
          }
        }
        allKontentItemLink {
          nodes {
            ...Link
          }
        }
      }
    `
  );

  // NOTE internalLinks = [{ codename: 'docs_8_5', id: '8ea97ebz6', url: '/8.5', versionId: '8.5' }, {etc...}]
  const internalLinks = allKenticoLink.nodes;
  const customLinks = allKontentItemLink.nodes;

  return useMemo(() => {
    const getInternalLink = ({ codename, id }: { codename?: string; id?: string }) => {
      const hasCodenameToFind = Boolean(codename);
      const hasIdToFind = Boolean(id);

      // NOTE Up to 2 links can be found for a page, a 'normal' and a 'latest'.
      // Normal: /9.6/my-page, Latest: /latest/my-page
      const matchingLinks = internalLinks.filter((link) => {
        if (hasCodenameToFind && link.codename === codename) {
          return true;
        }
        if (hasIdToFind && link.pageId === id) {
          return true;
        }

        return false;
      });

      const latestLink = matchingLinks.find((link) => link.url.includes(`/${LATEST_SLUG}`));

      // NOTE Use the '/latest' link when available
      const internalLink = latestLink || matchingLinks[0];
      const isBrokenLink = internalLink?.url === undefined;

      return isBrokenLink ? `/${LATEST_SLUG}/` : internalLink.url;
    };

    return {
      getInternalLink,
      internalLinks,
      customLinks,
    };
  }, [internalLinks, customLinks]);
};

export default useGlobalData;
