const slugify = (text: string, replacement = '-') => {
  return text
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/&/g, `${replacement}and${replacement}`) // Replace & with 'and'
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, replacement)
    .split(replacement)
    .filter((x) => x !== '') // remove potential empty leading and trailing entries
    .join(replacement);
};

export default slugify;
