import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby';

export const Wrapper = tw.div`
  relative
  grow
`;

export const VersionListItem = styled.li``;

export const VersionListItemArchived = styled.li`
  &:before {
    ${tw`content-['Archived versions']`}
    ${tw`block`}
    ${tw`mx-[8px] py-[6px]`}
    ${tw`whitespace-nowrap`}
    ${tw`text-xs text-neutrals-n8 text-center`}
    ${tw`font-medium`}
    ${tw`border-t border-solid border-neutrals-n5`}
  }

  & ~ &:before {
    ${tw`hidden`}
  }
`;

export const VersionList = tw.ul`
  absolute left-0 top-16 z-10
  bg-white
  border-2 border-borderColor
`;

export const VersionDropdownButton = tw.button`
  font-bold text-link text-sm
  flex flex-row items-center gap-0.5
  py-1.5 px-2
  rounded-md
  whitespace-nowrap
`;

export const VersionLink = styled(Link)`
  ${tw`min-w-[100px]`};
  ${tw`text-link text-sm`};
  ${tw`font-bold`}
  ${tw`pl-3 pr-6 py-3`};
  ${tw`text-left`};
  ${tw`hover:bg-neutrals-n2`}
  ${tw`flex gap-1`}
  ${tw`items-center`}
`;
