import { graphql } from 'gatsby';
import ExternalLink from './components/ExternalLink';
import InternalLink from './components/InternalLink';

interface LinkProps extends Queries.LinkFragment {
  className?: string;
}

const Link = ({ elements, className }: LinkProps): JSX.Element => {
  const title = elements.title.value;
  if (elements.action.value.__typename === 'kontent_item_link_action_internal') {
    return (
      <InternalLink {...elements.action.value} title={title} className={className}>
        {title}
      </InternalLink>
    );
  }
  return (
    <ExternalLink {...elements.action.value} title={title} className={className}>
      {title}
    </ExternalLink>
  );
};

export default Link;

export const fragment = graphql`
  fragment Link on kontent_item_link {
    __typename
    system {
      id
    }
    elements {
      title {
        value
      }
      action {
        value {
          ...LinkExternal
          ...LinkInternal
        }
      }
    }
  }
`;
