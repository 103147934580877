import tw, { styled } from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`px-6 pt-8 bg-white`}
`;

export const VersionWrapper = styled.div(({ isArchived }: { isArchived: boolean }) => [
  tw`font-bold text-[21px] mb-3`,
  tw`flex items-center justify-between`,
  isArchived && tw`pt-6 border-t border-t-borderColor`,
]);

export const ReleaseInfo = styled.div`
  ${tw`mb-[6px]`}
`;
export const ReleaseDate = styled.time`
  ${tw`font-saira font-bold  text-xs`}
`;

export const ReleaseCompatibility = styled.span`
  ${tw`italic ml-6`}
`;

export const ReleaseDescription = styled.div`
  ${tw`mb-[6px]`}
`;

export const LinkContainer = styled.div`
  &:not(:first-of-type) {
    ${tw`mt-2`}
  }
`;

export const DownloadLink = styled.a`
  ${tw`inline-block`}
  ${tw`text-link text-sm`}
  ${tw`font-medium`}
  ${tw`hover:text-link-hover`}
  &:not(:last-of-type) {
    ${tw`mr-6`}
  }
`;

export const DownloadInfo = styled.span`
  ${tw`text-sm`}
  ${tw`pr-4`}
`;
