import { Chevron } from '@components/Chevron/Chevron.styles';
import tw, { styled } from 'twin.macro';

// NOTE Made use of the details tag for seo and accessbility friendliness: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details
export const Wrapper = styled.details`
  ${tw`bg-neutrals-n2`}
  ${tw`mb-9`}
  ${tw`border-borderColor border`}

  &[open] {
    & summary svg {
      transform: rotate(180deg);
    }
  }
`;

export const Button = styled.summary`
  ${tw`py-6 pr-6 pl-[3.7rem]`}
  ${tw`text-sm text-left font-medium relative list-none cursor-pointer`}

  &::-webkit-details-marker {
    display: none;
  }
`;

export const Content = styled.div`
  ${tw`p-6`}
  ${tw`all-child:last:mb-0`}
  ${tw`border-t border-t-borderColor`}
  ${tw`text-sm`}
  ${tw`all-child:first-of-type:mt-0`}
`;

export const StyledChevron = styled(Chevron)`
  ${tw`absolute left-6 rotate-90`}
`;
