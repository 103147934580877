import tw, { styled, css } from 'twin.macro';

export const getBorderColor = (color: Queries.kontent_snippet_theme_theme__color_enum) => {
  switch (color) {
    case 'red':
      return tw`bg-linear-red-grad-horizontal lg:bg-linear-red-grad-vertical`;
    case 'blue':
      return tw`bg-linear-blue-grad-horizontal lg:bg-linear-blue-grad-vertical`;
  }
};

export const StyledColumnBlockItem = styled.div`
  ${tw`flex flex-col flex-1`}
  ${tw`relative`}
`;

const linkStyles = css`
  ${tw`block`}
  ${tw`text-secondary-s2-blue text-xs`}
  ${tw`font-bold`}
  ${tw`uppercase`}
  ${tw`after:[content:'_>']`}
  ${tw`mt-[16px]`}
  
  svg {
    ${tw`hidden`}
  }
`;

export const StyledColumnBlock = styled.section`
  li:has(a:first-of-type:is(:last-of-type)) {
    ${linkStyles}
  }

  & a:not(li *) {
    ${linkStyles}
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    ${tw`pb-[16px]`}
    ${tw`font-bold`}
    ${tw`text-m text-neutrals-n9`}
  }

  p {
    ${tw`pb-[24px]`}
    ${tw`text-sm text-neutrals-n8`}
    ${tw`flex-1`}
  }

  svg {
    ${tw`mb-6`}
  }
`;

export const StyledColumnBlockContainer = styled.div<{
  color: Queries.kontent_snippet_theme_theme__color_enum;
  hasSeparators?: boolean;
}>`
  ${tw`container`}
  ${tw`px-6`}
  ${tw`flex flex-col lg:flex-row justify-between gap-[32px]`}

  ${({ hasSeparators }) => (hasSeparators ? tw`lg:gap-[140px]` : tw`lg:gap-[32px]`)}
  
  ${StyledColumnBlockItem} {
    ${({ hasSeparators, color }) =>
      hasSeparators &&
      css`
        &:not(:last-child):after {
          ${tw`block`}
          ${tw`content-['']`}
          ${tw`absolute`}
          ${tw`bottom-[-20px] left-0 right-0 lg:left-auto lg:top-0 lg:right-[-70px]`}
          ${tw`h-[3px] lg:h-auto lg:w-[3px]`}
          ${getBorderColor(color)}
        }
      `}
  }
`;
