import { useState } from 'react';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<void>;

const useCopyToClipboard = (): { value: CopiedValue; copy: CopyFn } => {
  const [value, setValue] = useState<CopiedValue>(null);
  const copy: CopyFn = async (text) => {
    if (typeof navigator?.clipboard?.writeText === 'function') {
      await navigator.clipboard.writeText(text);
      setValue(text);
    }
  };
  return { value, copy };
};

export default useCopyToClipboard;
