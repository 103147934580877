import { Link } from 'gatsby';
import tw, { styled } from 'twin.macro';

export const AnchorHeadingWrapper = styled.h2`
  ${tw`flex items-center relative`}
  ${tw`mt-12 mb-4`}
  ${tw`text-md font-bold`}
  &:hover svg {
    ${tw`inline-block`}
  }
`;

export const StyledLink = styled(Link)`
  ${tw`relative`}
  ${tw`text-neutrals-n9`}
`;

export const AnchorIcon = styled.svg<{ type: string }>`
  ${({ type }) => type === 'h2' && tw`w-8`}
  ${({ type }) => type !== 'h2' && tw`w-7`}
  ${tw`hidden`}
  ${tw`ml-2`}
  ${tw`fill-neutrals-n7 hover:fill-primary-purple`}
`;
