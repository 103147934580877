import { LATEST_SLUG } from '@constants/global';
import getHomepageUrlByVersionId from '@utils/getHomepageUrlByVersionId';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

export type GetVersionUrlProps = {
  newVersionId: string;
  currentPath: string;
  currentVersionId: string;
};

const useProductVersions = (productCodename?: string) => {
  const { allKenticoLink, allKontentItemProduct, allKontentItemVersion, allProductVersions } =
    useStaticQuery<Queries.ProductVersionsDataQuery>(graphql`
      query ProductVersionsData {
        allKenticoLink {
          nodes {
            codename
            pageId
            isHomepage
            url
            versionId
          }
        }
        allKontentItemVersion {
          nodes {
            ...Version
          }
        }
        allKontentItemProduct {
          nodes {
            ...Product
          }
        }
        allProductVersions {
          nodes {
            latestVersion {
              id
            }
            product
            sortedVersions {
              id
              isLts
              isArchived
              logo {
                description
                url
              }
              name
              urlSlug
              menuText
            }
          }
        }
      }
    `);

  const productVersions = allProductVersions.nodes.find(
    (productVersion) => productVersion.product === productCodename
  );

  const isUsingVersions = productVersions !== undefined;
  const { latestVersion, sortedVersions } = productVersions || {};
  const latestVersionId = latestVersion?.id;

  // NOTE internalLinks = [{ codename: 'docs_8_5', id: '8ea97ebz6', url: '/8.5', versionId: '8.5' }, {etc...}]
  const internalLinks = allKenticoLink.nodes;
  const versionList = allKontentItemVersion.nodes;
  const productList = allKontentItemProduct.nodes;

  return useMemo(() => {
    const getVersionUrl = ({ newVersionId, currentPath, currentVersionId }: GetVersionUrlProps) => {
      const isNewVersionLatest = newVersionId === latestVersionId;
      const currentVersionUrlSlug = getVersionById(currentVersionId)?.urlSlug;
      const newVersionUrlSlug = getVersionById(newVersionId)?.urlSlug;

      const findValue = new RegExp(`/(latest|${currentVersionUrlSlug})/`);
      const replaceValue = isNewVersionLatest ? LATEST_SLUG : newVersionUrlSlug;
      const newPath = currentPath.replace(findValue, `/${replaceValue}/`);

      const isPathExists = internalLinks.some((link) => link.url === newPath);

      if (isPathExists) {
        return newPath;
      }

      return getHomepageUrlByVersionId({
        internalLinks,
        latestVersionId,
        productCodename,
        versionId: newVersionId,
      });
    };

    const getVersionPages = (versionId?: string) =>
      versionList.find((version) => version.system.codename === versionId);

    const getProductPages = (productCodename?: string) => {
      return productList.find((productItem) => productItem.system.codename === productCodename);
    };

    const getMenuPages = (versionId?: string) => {
      if (isUsingVersions) {
        return getVersionPages(versionId)?.value?.subpages?.value;
      }

      const productPages = getProductPages(productCodename)?.value?.subpages?.value;

      if (!productPages) {
        return undefined;
      }

      return productPages.filter((page) => page.__typename !== 'kontent_item_version');
    };

    const getVersionById = (versionId: string) =>
      sortedVersions?.find((version) => version.id === versionId);

    const sortedVersionByIsArchived = sortedVersions
      ? [...sortedVersions].sort((a, b) => {
          if (a.isArchived && !b.isArchived) return 1;
          if (!a.isArchived && b.isArchived) return -1;
          return 0;
        })
      : sortedVersions;

    return {
      isUsingVersions,
      latestVersionId,
      sortedVersions: sortedVersionByIsArchived,
      getMenuPages,
      getVersionById,
      getVersionUrl,
    };
  }, [
    internalLinks,
    latestVersionId,
    isUsingVersions,
    productCodename,
    productList,
    sortedVersions,
    versionList,
  ]);
};

export const fragment = graphql`
  fragment Version on kontent_item_version {
    value {
      subpages {
        value {
          ...PageTreeItemRecursive
          ...Page
        }
      }
      is_lts {
        value
      }
      is_archived {
        value
      }
      version_number {
        value
      }
      name {
        value
      }
      url_slug {
        value
      }
      logo {
        value {
          url
          description
        }
      }
    }
    system {
      id
      codename
      type
    }
  }

  fragment Product on kontent_item_product {
    value {
      subpages {
        value {
          __typename
          ...PageTreeItemRecursive
          ...Page
          ...Version
        }
      }
    }
    system {
      id
      codename
      type
    }
  }

  fragment PageTreeItemRecursive on kontent_item_page_tree_item {
    ...PageTreeItem
    __typename
    elements {
      subpages {
        value {
          ...Page
          ... on kontent_item_page_tree_item {
            ...PageTreeItem
            elements {
              subpages {
                value {
                  ...Page
                  ... on kontent_item_page_tree_item {
                    ...PageTreeItem
                    elements {
                      subpages {
                        value {
                          ...Page
                          ... on kontent_item_page_tree_item {
                            ...PageTreeItem
                            elements {
                              subpages {
                                value {
                                  ...Page
                                  ... on kontent_item_page_tree_item {
                                    ...PageTreeItem
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  fragment Page on kontent_item_page {
    __typename
    elements {
      short_title {
        value
      }
      title {
        value
      }
      url_slug {
        value
      }
    }
    system {
      codename
      id
      type
    }
  }

  fragment PageTreeItem on kontent_item_page_tree_item {
    __typename
    elements {
      title {
        value
      }
      url_slug {
        value
      }
    }
    system {
      codename
      id
      type
    }
  }
`;

export default useProductVersions;
