import { graphql, Link as GatsbyLink } from 'gatsby';
import { ReactNode } from 'react';
import useGlobalData from '@hooks/useGlobalData';
import slugify from '@utils/slugify';

interface InternalLinkProps extends Queries.LinkInternalFragment {
  children: ReactNode;
  title: string;
  className?: string;
}

const InternalLink = ({ children, title, elements, className }: InternalLinkProps) => {
  const { getInternalLink } = useGlobalData();
  const internalLinkURL = getInternalLink({
    id: elements.page.value.system.id,
  });

  const query = elements?.query?.value;
  const queryValue = query ? `?${query}` : '';

  let hash: string | undefined = undefined;
  switch (elements?.hash?.value?.__typename) {
    case 'kontent_item_anchor_heading':
      hash = elements.hash.value.elements.url_slug.value;
      break;
    case 'kontent_item_tabs_item':
      hash =
        elements.hash.value.tabElements.url_slug?.value ||
        slugify(elements.hash.value.tabElements.title.value);
      break;
  }

  const hashValue = hash ? `#${hash}` : '';

  return (
    <GatsbyLink className={className} to={internalLinkURL + queryValue + hashValue} title={title}>
      {children}
    </GatsbyLink>
  );
};

export default InternalLink;

export const fragment = graphql`
  fragment LinkInternal on kontent_item_link_action_internal {
    __typename
    elements {
      page {
        value {
          ... on kontent_item_page {
            system {
              id
            }
          }
        }
      }
      hash {
        value {
          __typename
          ...AnchorHeading
          ... on kontent_item_tabs_item {
            tabElements: elements {
              url_slug {
                value
              }
              title {
                value
              }
            }
          }
        }
      }
      query {
        value
      }
    }
  }
`;
