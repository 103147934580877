import tw, { styled } from 'twin.macro';
import { ImageElement } from '@kontent-ai/gatsby-components';
import { type ButtonVariant, variants } from '@components/homepage/Link/Link.styles';
import Link from '@components/Link/Link';

const backgroundColors = {
  red: 'background: linear-gradient(81deg, #FD3456 0%, #DF53FF 100%);',
  purple: 'background: linear-gradient(81deg, #DF53FF 0%, #2228A4 100%);',
  blue: 'background: linear-gradient(81deg, #2228A4 0%, #65D1FF 100%);',
};

export const StyledSection = styled.section<{
  color: Queries.kontent_snippet_theme_theme__color_enum | null;
  hasBackgroundImage: boolean;
  isFullWidth: boolean;
}>`
  ${({ hasBackgroundImage }) => hasBackgroundImage && tw`relative`}
  ${({ isFullWidth, color }) => isFullWidth && backgroundColors[color]}
  ${tw`text-center`}
`;

export const StyledContainer = styled.div<{
  color: Queries.kontent_snippet_theme_theme__color_enum;
  isFullWidth: boolean;
}>`
  ${tw`container`}
  ${tw`flex flex-col justify-center`}
  ${tw`p-6 md:px-0`}
  ${({ isFullWidth, color }) => !isFullWidth && backgroundColors[color]}
`;

export const StyledHeading = styled.p`
  ${tw`mb-[48px]`}
  ${tw`font-saira font-bold`}
  ${tw`text-neutrals-n1 text-xxl`}
  ${tw`z-0`}
`;

export const StyledLink = styled(Link)<{ variant: ButtonVariant }>`
  ${tw`first-of-type:md:mr-3 first-of-type:mb-2 first-of-type:md:mb-0`}
  ${({ variant }) => variant && variants[variant]}
`;

export const StyledButtonsWrapper = styled.div`
  ${tw`flex justify-center flex-col md:flex-row`}
  ${tw`z-0`}
`;

export const StyledResponsiveBackgroundImage = styled(ImageElement)`
  ${tw`absolute top-0 left-0`}
  ${tw`h-full`}
  ${tw`w-full`}
  ${tw`z-0`}

  &:before {
    ${tw`hidden`}
  }
`;
