import { debounce } from 'lodash';
import { InputWrapper, ResetButton, RoundedCross, StyledInput } from './Input.styles';
import { SearchBoxProps, useSearchBox } from 'react-instantsearch-hooks-web';
import { ChangeEvent, useCallback, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const DEBOUNCE_TIME = 200;

// NOTE We provide a custom queryHook function to debounce the calls to Algolia.
const queryHook: SearchBoxProps['queryHook'] = debounce((query, search) => {
  search(query);
}, DEBOUNCE_TIME);

function Input() {
  const data = useStaticQuery(graphql`
    query SearchInput {
      kontentItemRoot {
        elements {
          search_placeholder {
            value
          }
        }
      }
    }
  `);
  const [searchValue, setSearchValue] = useState('');
  const { clear, refine } = useSearchBox({ queryHook });
  const searchPlaceholder = data.kontentItemRoot.elements.search_placeholder?.value || '';

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // NOTE Reflect what the used typed instantly in the input
      setSearchValue(e.target.value);

      // NOTE Trigger Algolia's call which goes through the customized and debounced queryHook
      refine(e.target.value);
    },
    [refine]
  );

  const handleOnReset = useCallback(() => {
    setSearchValue('');
    clear();
  }, [clear]);

  return (
    <InputWrapper>
      <StyledInput
        id="search"
        onChange={handleOnChange}
        placeholder={searchPlaceholder}
        type="search"
        value={searchValue}
      />
      {Boolean(searchValue) && (
        <ResetButton onClick={handleOnReset} title="Reset">
          <RoundedCross aria-label="Reset search" />
        </ResetButton>
      )}
    </InputWrapper>
  );
}

export default Input;
