import tw, { styled } from 'twin.macro';
import { Link } from 'gatsby';

export const Wrapper = styled.div`
  ${tw`relative`};
`;

export const ProductList = styled.ul`
  ${tw`absolute z-10`}
  ${tw`w-56`}
  ${tw`bg-white`}
  ${tw`border-2 border-borderColor `}
`;

export const ProductDropdownButton = styled.button`
  ${tw`h-16`};
  ${tw`font-bold text-link`};
  ${tw`flex flex-row items-center`};
  ${tw`rounded-md`}
`;

export const ProductLink = styled(Link)`
  ${tw`text-link text-left`};
  ${tw`font-bold`};
  ${tw`w-full`};
  ${tw`hover:bg-neutrals-n2`}
  ${tw`block`}
  ${tw`pl-4 pr-6 py-1`}
`;
