import RichText from '../RichText/RichText';
import { graphql } from 'gatsby';
import { Wrapper, CalloutMessage } from './Callout.styles';

interface Props {
  message: Queries.CalloutFragment['elements']['message'];
  variant?: Queries.kontent_item_callout_elements_variant_enum;
}

const Callout = ({ message, variant = 'info' }: Props) => {
  return (
    <Wrapper variant={variant} role="alert">
      <CalloutMessage>
        <RichText {...message} />
      </CalloutMessage>
    </Wrapper>
  );
};

export const fragment = graphql`
  fragment Callout on kontent_item_callout {
    __typename
    elements {
      message {
        images {
          description
          height
          url
          image_id
          width
        }
        links {
          url_slug
          type
          link_id
          codename
        }
        modular_content {
          ... on kontent_item {
            system {
              codename
              type
            }
          }
          ...CodeSnippet
        }
        value
      }
      variant {
        value
      }
    }
  }
`;

export default Callout;
