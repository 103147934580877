import RichText from '@components/RichText/RichText';
import { graphql } from 'gatsby';

type Props = {
  content: Queries.SharedContentFragment['elements']['content'];
};

const SharedContent = ({ content }: Props) => {
  return <RichText {...content} />;
};

export const fragment = graphql`
  fragment SharedContent on kontent_item_shared_content {
    __typename
    elements {
      content {
        images {
          description
          height
          url
          image_id
          width
        }
        links {
          url_slug
          type
          link_id
          codename
        }
        value
        modular_content {
          ... on kontent_item {
            system {
              codename
              type
            }
          }
          ...AnchorHeading
          ...Callout
          ...CodeSnippet
          ...Collapsible
          ...Iframe
          ...Video
          ...SonarScanner
          ...Tabs
          ...Image
          ...Table
          ...Link
        }
      }
    }
  }
`;

export default SharedContent;
