import { graphql } from 'gatsby';
import { type ButtonVariant } from '../Link/Link.styles';
import {
  StyledBody,
  StyledButtons,
  StyledContainer,
  StyledContent,
  StyledHeadline,
  StyledImageHero,
  StyledLink,
  StyledSection,
  StyledSubheadline,
} from './HeroBlock.styles';

const buttonVariant: Record<
  Queries.kontent_snippet_theme_theme__color_enum,
  [ButtonVariant, ButtonVariant]
> = {
  red: ['primary_repeat_red', 'secondary_repeat_red'],
  blue: ['primary_persistence_purple', 'secondary_persistence_purple'],
};

const HeroBlock = ({ elements }: Queries.HeroBlockFragment) => {
  const { body, buttons, headline, image, image_dropshadow, subheadline, theme__color } = elements;
  const hasShadow = Boolean(image_dropshadow?.value?.includes('shadow'));
  const colorValue = theme__color.value;
  const hasButtons = !!buttons?.value?.length;
  const hasSubheadline = !!subheadline?.value;

  const IMAGE_WIDTH = 544;
  const IMAGE_HEIGHT = 370;
  const imageHero = image?.value && {
    ...image.value,
    description: image.value.description ?? image.value.url,
    image_id: image.value.url,
    width: image.value.width ?? IMAGE_WIDTH,
    height: image.value.height ?? IMAGE_HEIGHT,
  };

  return (
    <StyledSection color={colorValue}>
      <StyledContainer>
        <StyledContent>
          {hasSubheadline && (
            <StyledSubheadline color={colorValue}>{subheadline.value}</StyledSubheadline>
          )}
          <StyledHeadline>{headline.value}</StyledHeadline>
          <StyledBody>{body.value}</StyledBody>
          {hasButtons && (
            <StyledButtons>
              {buttons.value.map((button, variantIndex) => (
                <StyledLink
                  {...button}
                  key={button.system.id}
                  variant={buttonVariant[colorValue][variantIndex]}
                />
              ))}
            </StyledButtons>
          )}
        </StyledContent>
        {imageHero && (
          <StyledImageHero image={imageHero} alt={imageHero.description} hasShadow={hasShadow} />
        )}
      </StyledContainer>
    </StyledSection>
  );
};

export default HeroBlock;

export const fragment = graphql`
  fragment HeroBlock on kontent_item_hero_block {
    __typename
    elements {
      ...Theme
      buttons {
        value {
          system {
            id
          }
          ...Link
        }
      }
      subheadline {
        value
      }
      headline {
        value
      }
      body {
        value
      }
      image {
        value {
          url
          description
          width
          height
        }
      }
      image_dropshadow {
        value
      }
    }
    system {
      type
      id
    }
  }
`;
