const getTextFromElement = (element: JSX.Element[] | JSX.Element | string): string => {
  if (typeof element === 'string') {
    return element;
  }

  if (Array.isArray(element)) {
    return element.map(getTextFromElement).join('');
  }

  if (element.props.children) {
    return getTextFromElement(element.props.children);
  }

  return '';
};

export default getTextFromElement;
