import MenuCategory from '../MenuCategory/MenuCategory';
import MenuLink from '../MenuLink/MenuLink';

export type MenuItemType =
  | Queries.PageTreeItemFragment
  | Queries.PageTreeItemRecursiveFragment
  | Queries.PageFragment;

type Props = {
  isFirstLevel?: boolean;
  item: MenuItemType;
};

const MenuItem = ({ item, isFirstLevel = false }: Props) =>
  item.__typename === 'kontent_item_page' ? (
    <MenuLink key={item.system.id} page={item} isFirstLevel={isFirstLevel} />
  ) : (
    <MenuCategory key={item.system.id} item={item} isFirstLevel={isFirstLevel} />
  );

export default MenuItem;
