import { graphql } from 'gatsby';
import tw, { type TwStyle } from 'twin.macro';

const calcPaddingTop = (value: Queries.kontent_snippet_padding_padding__top_enum): TwStyle => {
  switch (value) {
    case 'medium':
      return tw`pt-[32px] md:pt-[64px]`;
    case 'large':
      return tw`pt-[64px] md:pt-[128px]`;
    case 'none':
      return tw`pt-0`;
  }
};

const calcPaddingBottom = (
  value: Queries.kontent_snippet_padding_padding__bottom_enum
): TwStyle => {
  switch (value) {
    case 'medium':
      return tw`pb-[32px] md:pb-[64px]`;
    case 'large':
      return tw`pb-[64px] md:pb-[128px]`;
    case 'none':
      return tw`pb-0`;
  }
};

export const DEFAULT_PADDING = 'medium';

export type PaddingProps = {
  paddingTop: Queries.kontent_snippet_padding_padding__top_enum;
  paddingBottom: Queries.kontent_snippet_padding_padding__bottom_enum;
};

export type PaddingGeneratorArgs = {
  top: Queries.kontent_snippet_padding_padding__top_enum | null;
  bottom: Queries.kontent_snippet_padding_padding__bottom_enum | null;
};

export const forwardPadding = (props: Queries.PaddingFragment): PaddingProps => ({
  paddingTop: props.padding__top?.value ?? DEFAULT_PADDING,
  paddingBottom: props.padding__bottom?.value ?? DEFAULT_PADDING,
});

export const generatePadding = (props: PaddingGeneratorArgs): Queries.PaddingFragment => ({
  padding__top: { value: props.top },
  padding__bottom: { value: props.bottom },
});

export const applyPaddingCSS = (props: Queries.PaddingFragment): TwStyle[] =>
  applyPaddingStyles(forwardPadding(props));

export const applyPaddingStyles = (props: PaddingProps): TwStyle[] => [
  calcPaddingTop(props.paddingTop),
  calcPaddingBottom(props.paddingBottom),
];

export const fragment = graphql`
  fragment Padding on kontent_snippet_padding {
    padding__top {
      value
    }
    padding__bottom {
      value
    }
  }
`;
