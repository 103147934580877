import { MenuFooterWrapper } from './MenuFooter.styles';
import RichText, { RichTextProps } from '@components/RichText/RichText';

const MenuFooter = ({ body }: { body?: RichTextProps }) => {
  if (!body) {
    return null;
  }

  return (
    <MenuFooterWrapper>
      <RichText {...body} />
    </MenuFooterWrapper>
  );
};

export default MenuFooter;
