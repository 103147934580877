import { graphql } from 'gatsby';
import { VideoContainer, VideoIFrame, Figure, Figcaption } from './Video.styles';

const Video = ({ videoElements: elements }: Queries.VideoFragment) => (
  <Figure alignment={elements.alignment.value} width={elements.width.value}>
    <VideoContainer>
      <VideoIFrame
        src={elements.url.value}
        title={elements.description?.value ?? 'Embedded Video'}
        allowFullScreen
      />
    </VideoContainer>
    {elements.description?.value ? <Figcaption>{elements.description.value}</Figcaption> : null}
  </Figure>
);

export default Video;

export const fragment = graphql`
  fragment Video on kontent_item_video {
    __typename
    system {
      id
    }
    videoElements: elements {
      url {
        value
      }
      width {
        value
      }
      alignment {
        value
      }
      description {
        value
      }
    }
  }
`;
