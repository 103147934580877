import { ArrowTheme, ArrowType } from './Arrow';
import tw, { styled } from 'twin.macro';

export const StyledArrow = styled.svg(({ isOpen, theme }: ArrowType) => [
  tw`w-8 h-8`,
  tw`rotate-90`,
  isOpen && tw`-rotate-90`,
  theme === ArrowTheme['header-dropdown'] && tw`fill-secondary-s2-blue`,
  theme === ArrowTheme['menu-dropdown'] && tw`fill-neutrals-n7`,
]);
