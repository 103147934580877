import { graphql } from 'gatsby';
import { StyledFooter } from './Footer.styles';
import { default as FooterContent } from '../../Footer/Footer';

const Footer = ({ footerElements }: Queries.FooterHomepageFragment) => {
  const { content } = footerElements;

  return (
    <StyledFooter>
      <FooterContent richTextValue={content.value} />
    </StyledFooter>
  );
};

export default Footer;

export const fragment = graphql`
  fragment FooterHomepage on kontent_item_footer_homepage {
    __typename
    system {
      codename
    }
    footerElements: elements {
      content {
        value
      }
    }
  }
`;
