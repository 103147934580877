import { graphql } from 'gatsby';
import { useEffect, useState } from 'react';
import SonarScannerVersion from './components/SonarScannerVersion/SonarScannerVersion';
import {
  Wrapper,
  ScannerHeader,
  ScannerHeaderTitle,
  ScannerHeaderItem,
  LoadingPlaceholder,
  ShowMoreButton,
  StyledLink,
} from './SonarScanner.styles';

const SonarScanner = ({
  buildSystem,
}: {
  buildSystem: Queries.SonarScannerFragment['elements']['build_system']['value'];
}) => {
  const [data, setData] = useState<ScannerData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isArchivedVersionsVisible, setOlderVersionsVisibility] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(
          `https://downloads.sonarsource.com/sonarqube/update/scanner${buildSystem}.json`,
          {
            signal: abortController.signal,
          }
        );
        const result = await response.json();
        setData(result);
        setIsLoading(false);
      } catch (error) {
        setData(null);
        setIsLoading(false);
        console.error(error);
      }
    };

    fetchData().catch((error) => console.error('SonarScanner error:', error));

    return () => {
      abortController.abort();
    };
  }, [buildSystem]);

  if (!data) {
    return isLoading ? <LoadingPlaceholder /> : null;
  }

  const { isSonarSourceCommercial, issueTrackerURL, versions } = data;
  const archivedVersions = versions.filter((version) => version.archived);
  const currentVersions = versions.filter((version) => !version.archived);
  const hasArchivedVersions = Boolean(archivedVersions?.length);
  const hasCurrentVersion = Boolean(currentVersions?.length);

  return (
    <Wrapper>
      <ScannerHeader>
        <ScannerHeaderItem>
          <ScannerHeaderTitle>{data.name}</ScannerHeaderTitle>
        </ScannerHeaderItem>
        {issueTrackerURL && (
          <ScannerHeaderItem>
            <StyledLink href={issueTrackerURL} target="_blank" rel="noreferrer">
              Issue Tracker
            </StyledLink>
          </ScannerHeaderItem>
        )}
        {isSonarSourceCommercial && <ScannerHeaderItem>Supported by SonarSource</ScannerHeaderItem>}
      </ScannerHeader>
      {hasArchivedVersions && (
        <ShowMoreButton
          expanded={isArchivedVersionsVisible}
          onClick={() => {
            setOlderVersionsVisibility((prev) => !prev);
          }}
        >
          {isArchivedVersionsVisible ? 'Show fewer' : 'Show more'}
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6.3439 7.75781L4.92969 9.17203L12.0007 16.2431L19.0718 9.17205L17.6576 7.75784L12.0008 13.4147L6.3439 7.75781Z" />
          </svg>
        </ShowMoreButton>
      )}
      {hasCurrentVersion &&
        currentVersions.map((version) => (
          <SonarScannerVersion versionItem={version} key={version.version} />
        ))}
      {isArchivedVersionsVisible &&
        archivedVersions.map((version) => (
          <SonarScannerVersion versionItem={version} key={version.version} />
        ))}
    </Wrapper>
  );
};

export const fragment = graphql`
  fragment SonarScanner on kontent_item_sonarscanner {
    __typename
    elements {
      build_system {
        value
      }
    }
  }
`;

export default SonarScanner;
