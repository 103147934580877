import tw, { styled } from 'twin.macro';

export const NavigationTextWrapper = styled.div`
  ${tw`flex`}
  ${tw`flex-col`}
  ${tw`gap-y-2`}
  ${tw`py-[24px]`}
  ${tw`text-sm`}
  ${tw`border-t-borderColor border-t-[1px]`}
  ${tw`text-primary-purple`}
  ${tw`font-medium`}

  a {
    ${tw`hover:underline`}

    &.external-link {
      span,
      strong,
      em {
        ${tw`hover:text-link-hover`}
      }

      svg {
        ${tw`fill-neutrals-n7 hover:fill-secondary-s4-purple mb-1`}
        ${tw`w-4`}
      }
    }
  }
`;
