import { Global } from '@emotion/react';
import isDocumentationPage from '../../../gatsby/utils/isDocumentationPage';
import { ToastContainer } from 'react-toastify';
import Navigation from '@components/Navigation/Navigation';
import Banner from '@components/Banner/Banner';
import { GlobalStyles as ResetAndBasicStyles } from 'twin.macro';
import globalStyles from '../../styles/globalStyles';
import { MainWrapper, NavWrapper, PageWrapper } from './Layout.styles';

export type LayoutProps = {
  children: JSX.Element;
  pageContext: PageContext;
  data: Queries.PageTemplateQuery & Queries.DocumentationPageTemplateQuery;
};

const MainLayout = ({ children, data, pageContext }: LayoutProps) => {
  const productCodename = pageContext?.product?.system.codename;
  const menuFooterContentBody = pageContext.product?.elements.menu_footer_content ?? undefined;
  const hasNavigationMenu = isDocumentationPage({
    productCodename,
    versionId: pageContext.versionId,
    isUsingVersions: pageContext.isUsingVersions,
  });
  const banner =
    data?.kontentItemVersion?.elements?.banner?.value ||
    data?.kontentItemPage?.elements?.banner?.value;

  return (
    <>
      <ResetAndBasicStyles />
      <Global styles={globalStyles} />
      <ToastContainer position="top-center" autoClose={4000} theme="colored" icon={false} />
      {!!banner && <Banner {...banner} />}
      <MainWrapper>
        {hasNavigationMenu && (
          <NavWrapper>
            <Navigation
              baseUrl={pageContext.baseUrl}
              menuFooterContentBody={menuFooterContentBody}
              productCodename={productCodename}
              versionId={pageContext.versionId}
            />
          </NavWrapper>
        )}
        <PageWrapper hasNavigationMenu={hasNavigationMenu}>{children}</PageWrapper>
      </MainWrapper>
    </>
  );
};

export default MainLayout;
