import MenuItem, { MenuItemType } from './components/MenuItem/MenuItem';
import { MenuList } from './Menu.styles';

type Props = {
  pages: ReadonlyArray<MenuItemType>;
};

const Menu = ({ pages }: Props) => (
  <MenuList>
    {pages.map((page) => (
      <MenuItem key={page.system.id} item={page} isFirstLevel />
    ))}
  </MenuList>
);

export default Menu;
