import { useLocation } from '@reach/router';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import MenuItem from '../MenuItem/MenuItem';
import Arrow, { ArrowTheme } from '@components/Arrow/Arrow';
import useGlobalData from '@hooks/useGlobalData';
import useIsMounted from '@hooks/useIsMounted';
import { CategoryButton, CategoryItemsWrapper, CategoryWrapper } from './MenuCategory.styles';

const MenuCategory = ({
  item: { elements, system },
  isFirstLevel = false,
}: {
  item: Queries.PageTreeItemFragment | Queries.PageTreeItemRecursiveFragment;
  isFirstLevel?: boolean;
}) => {
  const { pathname } = useLocation();
  const { getInternalLink } = useGlobalData();
  const isMounted = useIsMounted();
  const fullUrlSlug = getInternalLink({ codename: system.codename });
  const isCategoryActive = pathname.includes(fullUrlSlug);

  const [isExpanded, setIsExpanded] = useState<boolean>(isCategoryActive);

  useEffect(() => {
    if (isMounted) {
      setIsExpanded(isCategoryActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCategoryActive]);

  function toggleIsExpanded() {
    setIsExpanded((prev) => !prev);
  }

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      toggleIsExpanded();
    }
  }, []);

  return (
    <CategoryWrapper>
      <CategoryButton
        onClick={toggleIsExpanded}
        onKeyDown={onKeyDown}
        tabIndex={0}
        aria-expanded={isExpanded}
        aria-label={elements.title.value}
        isExpanded={isExpanded}
        isFirstLevel={isFirstLevel}
      >
        {elements.title.value}
        <Arrow isOpen={isExpanded} theme={ArrowTheme['menu-dropdown']} />
      </CategoryButton>
      {'subpages' in elements && isExpanded && (
        <CategoryItemsWrapper>
          {elements.subpages.value.map((item) => (
            <MenuItem key={item.system.id} item={item} />
          ))}
        </CategoryItemsWrapper>
      )}
    </CategoryWrapper>
  );
};

export default MenuCategory;
