import { useRef, useState } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import Arrow, { ArrowTheme } from '@components/Arrow/Arrow';
import { ProductLink, ProductList, ProductDropdownButton, Wrapper } from './ProductDropdown.styles';
import useProducts from '@hooks/useProducts';
import { Link } from 'gatsby';
import Logo from './components/Logo/Logo';

const ProductDropdown = ({ selectedProduct }: { selectedProduct?: string }) => {
  const { getProductHomepageUrl, products } = useProducts();
  const [isOpen, setIsOpen] = useState(false);
  const componentRef = useRef(null);

  useClickOutside(componentRef, () => {
    setIsOpen(false);
  });

  const currentProduct = products.find((product) => product.name === selectedProduct);

  if (!currentProduct) {
    console.log('ProductDropdown: could not get current product data.');
    return null;
  }

  const productItems = products.map((product) => {
    const linkUrl = getProductHomepageUrl(product.name);

    return (
      <li key={product.name}>
        <ProductLink
          title={product.logoTitle?.value || product.name}
          to={linkUrl}
          onClick={() => setIsOpen(false)}
        >
          <Logo {...product} isListItem />
        </ProductLink>
      </li>
    );
  });

  const selectedProductLogo = <Logo {...currentProduct} />;

  return products.length > 1 ? (
    <Wrapper ref={componentRef}>
      <ProductDropdownButton
        aria-label="Display Sonar products"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {selectedProductLogo}
        <Arrow isOpen={isOpen} theme={ArrowTheme['header-dropdown']} />
      </ProductDropdownButton>
      {isOpen && <ProductList>{productItems}</ProductList>}
    </Wrapper>
  ) : (
    <Link title={currentProduct.name} to={getProductHomepageUrl(currentProduct.name)}>
      {selectedProductLogo}
    </Link>
  );
};

export default ProductDropdown;
