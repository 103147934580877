import tw, { styled, css } from 'twin.macro';
import { ImageElement } from '@kontent-ai/gatsby-components';
import { getBackgroundColor, getTextColor } from '@utils/theme';
import Link from '@components/Link/Link';
import { type ButtonVariant, variants } from '@components/homepage/Link/Link.styles';

export const StyledSection = styled.section<{
  color: Queries.kontent_snippet_theme_theme__color_enum;
}>`
  ${tw`relative`}
  ${tw`pt-11 pb-[110px] lg:pt-[110px]`}
  ${tw`text-center lg:text-left`}
  ${({ color }) => getBackgroundColor(color)}
`;

export const StyledContainer = styled.div`
  ${tw`container`}
  ${tw`relative`}
  ${tw`px-6 md:px-0 pb-3 last:pb-7 lg:pb-0`}
  ${tw`z-10`}
  ${tw`block md:grid gap-x-7 lg:gap-7 lg:grid-cols-12 grid-cols-6`}
  ${tw`items-center`}
`;

export const StyledContent = styled.div`
  ${tw`md:px-3 lg:px-0`}
  ${tw`mb-5 lg:mb-0`}
  ${tw`lg:col-span-6 col-span-8`}
`;

export const StyledImageHero = styled(ImageElement, {
  shouldForwardProp: (propName) => propName !== 'hasShadow',
})<{ hasShadow?: boolean }>`
  ${tw`lg:col-span-6 lg:col-start-7 col-span-5 col-start-2`}
  ${tw`mb-8 md:mb-0`}
  ${({ hasShadow }) =>
    hasShadow &&
    css`
      ${tw`overflow-visible`}
      img {
        ${tw`drop-shadow-large`}
      }
    `}
`;

export const StyledSubheadline = styled.p<{
  color: Queries.kontent_snippet_theme_theme__color_enum;
}>`
  ${tw`font-saira font-bold`}
  ${tw`text-sm tracking-[0.15em] uppercase`}
  ${tw`my-0 mb-3`}
  ${tw`py-0`}
  ${({ color }) => getTextColor(color)}
`;

export const StyledHeadline = styled.div`
  ${tw`py-0 px-2 lg:px-0`}
  ${tw`my-0 mb-8`}
  ${tw`font-saira font-semibold`}
  ${tw`text-primary-purple text-xxl`}
  ${tw`leading-[64px]`}
`;

export const StyledBody = styled.p`
  ${tw`text-primary-purple text-base`}
  ${tw`font-maven-pro`}
  ${tw`mb-[48px]`}
  ${tw`last:mb-0`}
`;

export const StyledLink = styled(Link)<{ variant: ButtonVariant }>`
  ${tw`whitespace-normal`}
  ${({ variant }) => variant && variants[variant]}
`;

export const StyledButtons = styled.div`
  ${tw`grid md:flex md:items-center gap-2`}
  ${tw`justify-center lg:justify-start`}
`;
