import 'react-medium-image-zoom/dist/styles.css';
import { Fragment } from 'react';
import { graphql } from 'gatsby';
import Zoom from 'react-medium-image-zoom';
import { StyledFigure, StyledImage, StyledFigcaption } from './Image.styles';

const Image = ({ elements }: Queries.ImageFragment) => {
  const showCaption =
    elements.show_caption.value === 'yes' && elements.asset.value.description !== '';
  const caption = elements.asset.value.description || undefined;
  const Wrapper = elements.zoomable.value === 'yes' ? Zoom : Fragment;

  return (
    <Wrapper>
      <StyledFigure $align={elements.alignment.value} $width={elements.width.value}>
        <StyledImage
          image={{
            ...elements.asset.value,
            height: elements.asset.value.height ?? 100,
            width: elements.asset.value.width ?? 100,
          }}
          loading={elements.loading_strategy.value}
          alt={caption}
          objectFit="contain"
          title={caption}
        />
        {showCaption && <StyledFigcaption>{caption}</StyledFigcaption>}
      </StyledFigure>
    </Wrapper>
  );
};

export const fragment = graphql`
  fragment Image on kontent_item_image {
    __typename
    elements {
      asset {
        value {
          description
          url
          height
          width
          type
        }
      }
      show_caption {
        value
      }
      width {
        value
      }
      alignment {
        value
      }
      loading_strategy {
        value
      }
      zoomable {
        value
      }
    }
  }
`;

export default Image;
