import { graphql } from 'gatsby';
import { AnchorIcon, AnchorHeadingWrapper, StyledLink } from './AnchorHeading.styles';
import slugify from '@utils/slugify';

declare type AnchorHeadingProps = {
  heading: Queries.kontent_item_anchor_heading_elements_heading_enum;
  title: Queries.AnchorHeadingFragment['elements']['title']['value'];
  urlSlug?: Queries.AnchorHeadingFragment['elements']['url_slug']['value'];
};

const AnchorHeading = ({ heading, title, urlSlug }: AnchorHeadingProps) => {
  const slug = urlSlug ? urlSlug : slugify(title);

  return (
    <AnchorHeadingWrapper as={heading} id={slug}>
      <StyledLink to={`#${slug}`} title={title}>
        {title}
        <AnchorIcon
          type={heading}
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.46588 12.7069C9.59888 13.8399 11.5749 13.8399 12.7079 12.7069L13.4149 11.9999L14.8289 13.4139L14.1219 14.1209C13.1789 15.0649 11.9229 15.5859 10.5869 15.5859C9.25088 15.5859 7.99488 15.0649 7.05188 14.1209L4.92988 11.9999C3.99417 11.0612 3.46875 9.78985 3.46875 8.46444C3.46875 7.13902 3.99417 5.86765 4.92988 4.92894C5.39375 4.4644 5.94484 4.0961 6.55149 3.8452C7.15814 3.59431 7.80839 3.46575 8.46488 3.46693C9.12154 3.46557 9.77199 3.59403 10.3788 3.84493C10.9857 4.09584 11.5369 4.46424 12.0009 4.92894L12.7079 5.63594L11.2939 7.04994L10.5869 6.34294C10.0233 5.78193 9.26055 5.46697 8.46538 5.46697C7.67021 5.46697 6.90741 5.78193 6.34388 6.34294C5.78238 6.90621 5.46708 7.6691 5.46708 8.46444C5.46708 9.25977 5.78238 10.0227 6.34388 10.5859L8.46588 12.7069Z" />
          <path d="M11.9999 19.0713L11.2929 18.3643L12.7069 16.9503L13.4139 17.6573C13.9774 18.2183 14.7402 18.5332 15.5354 18.5332C16.3305 18.5332 17.0933 18.2183 17.6569 17.6573C18.2184 17.094 18.5337 16.3311 18.5337 15.5357C18.5337 14.7404 18.2184 13.9775 17.6569 13.4142L15.5349 11.2933C14.4019 10.1602 12.4259 10.1602 11.2929 11.2933L10.5859 12.0002L9.17188 10.5863L9.87887 9.87925C10.8219 8.93525 12.0779 8.41425 13.4139 8.41425C14.7499 8.41425 16.0059 8.93525 16.9489 9.87925L19.0709 12.0002C20.0066 12.939 20.532 14.2103 20.532 15.5357C20.532 16.8612 20.0066 18.1325 19.0709 19.0713C18.1324 20.0074 16.861 20.5332 15.5354 20.5332C14.2098 20.5332 12.9383 20.0074 11.9999 19.0713Z" />
        </AnchorIcon>
      </StyledLink>
    </AnchorHeadingWrapper>
  );
};

export default AnchorHeading;

export const fragment = graphql`
  fragment AnchorHeading on kontent_item_anchor_heading {
    __typename
    elements {
      heading {
        value
      }
      title {
        value
      }
      url_slug {
        value
      }
      hide_in_toc {
        value
      }
      text_in_toc {
        value
      }
    }
    system {
      codename
    }
  }
`;
