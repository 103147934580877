import tw, { styled } from 'twin.macro';

export const Figure = styled.figure<{
  width: Queries.VideoFragment['videoElements']['width']['value'];
  alignment: Queries.VideoFragment['videoElements']['alignment']['value'];
}>`
  ${({ width }) => {
    switch (width) {
      case 'percent_75':
        return tw`w-3/4`;
      case 'percent_50':
        return tw`w-1/2`;
      case 'percent_25':
        return tw`w-1/4`;
      case 'percent_100':
      default:
        return tw`w-full`;
    }
  }}
  ${({ alignment }) => {
    switch (alignment) {
      case 'left':
        return tw`mr-auto`;
      case 'right':
        return tw`ml-auto`;
      case 'center':
      default:
        return tw`mx-auto`;
    }
  }}
`;

export const VideoContainer = styled.div`
  ${tw`relative`}
  ${tw`pb-[56.25%]`}
`;

export const VideoIFrame = styled.iframe`
  ${tw`absolute`}
  ${tw`top-0 left-0`}
  ${tw`w-full h-full`}
`;

export const Figcaption = styled.figcaption`
  ${tw`text-neutrals-n7 text-sm`}
  ${tw`italic font-semibold`}
  ${tw`my-2`}
`;
