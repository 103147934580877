import { StyledImage } from './Logo.styles';

const LOGO_SIZE = 22;

const Logo = ({ url, description }: { url?: string | null; description: string }) =>
  !url ? null : (
    <StyledImage
      alt={description}
      objectFit="contain"
      image={{
        height: LOGO_SIZE,
        image_id: url,
        url,
        width: LOGO_SIZE,
      }}
      title={description}
    />
  );

export default Logo;
