import { graphql } from 'gatsby';
import RichText from '@components/RichText/RichText';
import { TableWrapper } from './Table.styles';
import resolveTableNode from './utils/resolveTableNode';

const Table = ({ elements }: { elements: Queries.TableFragment['elements'] }) => {
  const tableProps = {
    alignment: elements.tableAlignment.value,
    bodyTextAlignment: elements.body_text_alignment.value,
    bodyTextVerticalAlignment: elements.body_text_vertical_alignment.value,
    header: elements.header.value,
    headerTextAlignment: elements.header_text_alignment.value,
    headerTextWrap: elements.header_text_wrap.value,
    showBorders: elements.show_borders.value,
    showHeaderBackground: elements.show_header_background.value,
    $width: elements.tableWidth.value,
  };

  return (
    <TableWrapper {...tableProps}>
      <RichText
        {...elements.content}
        customResolveDomNode={(domNode: HTMLTableElement, domToReact: Function) =>
          resolveTableNode(domNode, domToReact, tableProps.header)
        }
      />
    </TableWrapper>
  );
};

export default Table;

export const fragment = graphql`
  fragment Table on kontent_item_table {
    __typename
    elements {
      content {
        images {
          description
          height
          url
          image_id
          width
        }
        links {
          url_slug
          type
          link_id
          codename
        }
        value
      }
      tableAlignment: alignment {
        value
      }
      body_text_alignment {
        value
      }
      body_text_vertical_alignment {
        value
      }
      header {
        value
      }
      header_text_alignment {
        value
      }
      header_text_wrap {
        value
      }
      show_borders {
        value
      }
      show_header_background {
        value
      }
      tableWidth: width {
        value
      }
    }
  }
`;
