const isDocumentationPage = ({
  productCodename,
  versionId,
  isUsingVersions,
}: {
  productCodename?: string;
  versionId?: string | null;
  isUsingVersions: boolean;
}) => {
  const hasProductParent = !!productCodename;
  const hasVersionParent = !!versionId;
  return (isUsingVersions && hasVersionParent) || (hasProductParent && !isUsingVersions);
};

export default isDocumentationPage;
