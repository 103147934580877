import { Link } from 'gatsby';
import tw, { css, styled } from 'twin.macro';

export const StyledLink = styled(Link)(() => [
  tw`block pt-3 pb-2`,
  tw`hover:text-primary-purple hover:underline pt-3 pb-2`,
  css`
    mark {
      ${tw`bg-ui-colours-ui2`}
    }
  `,
]);

export const Title = styled.div(tw`pb-1 text-sm font-medium`);
export const Content = styled.div(tw`block text-xs`);
