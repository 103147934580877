import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';
import { useLayoutEffect } from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import aa from 'search-insights';

// NOTE Mandatory middleware to allow the sendEvent method to work
// SOURCE https://www.algolia.com/doc/api-reference/widgets/insights/react-hooks/
export default function InsightsMiddleware({ userToken }: { userToken: string }) {
  const { use } = useInstantSearch();

  useLayoutEffect(() => {
    aa('setUserToken', userToken);

    const middleware = createInsightsMiddleware({
      insightsClient: aa,
    });

    return use(middleware);
  }, [use, userToken]);

  return null;
}
