/** @type {import('tailwindcss').Config} */

const colors = {
  primary: {
    purple: '#290042',
    red: '#FD3456',
  },
  secondary: {
    's1-blue': '#65D1FF',
    's2-blue': '#2228A4',
    's3-purple': '#DF53FF',
    's4-purple': '#39057B',
  },
  'ui-colours': {
    ui1: '#B29CBF',
    ui2: '#FFEBEE',
    'red-muted': '#EEC8D2',
    'blue-muted': '#D0EDFC',
  },
  neutrals: {
    n1: '#FFFFFF',
    n2: '#F4F7FB',
    n3: '#E8EFF7',
    n4: '#DDE6F3',
    n5: '#D3DCE9',
    n6: '#A8B3C2',
    n7: '#5F656D',
    n8: '#3B3F44',
    n9: '#000000',
  },
  utils: {
    transparent: '#0000ff00',
  },
};

module.exports = {
  content: ['./src/pages/*.tsx', './src/components/**/*.tsx'],
  theme: {
    extend: {
      spacing: {
        128: '32rem',
        256: '64rem',
        tableOfContentWidth: '220px',
      },
      backgroundImage: {
        infoIcon: "url('/images/info.svg')",
        warningIcon: "url('/images/warning.svg')",
        dangerIcon: "url('/images/danger.svg')",
        successIcon: "url('/images/success.svg')",
        'linear-red-grad-horizontal': `linear-gradient(90deg, rgba(253, 52, 86, 0.40) 0%, rgba(253, 52, 86, 0.00) 100%);`,
        'linear-blue-grad-horizontal': `linear-gradient(90deg, rgba(101, 209, 255, 0.70) 0%, rgba(101, 209, 255, 0.00) 100%)`,
        'linear-red-grad-vertical': `linear-gradient(180deg, rgba(253, 52, 86, 0.40) 0%, rgba(253, 52, 86, 0.00) 100%);`,
        'linear-blue-grad-vertical': `linear-gradient(180deg, rgba(101, 209, 255, 0.70) 0%, rgba(101, 209, 255, 0.00) 100%)`,
        'light-linear-red-grad-vertical': `linear-gradient(180deg, rgba(253, 52, 86, 0.10) 0.03%, rgba(253, 52, 86, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
        'light-linear-blue-grad-vertical': `linear-gradient(180deg, rgba(60, 130, 234, 0.10) 0.03%, rgba(60, 130, 234, 0.04) 64.06%, rgba(255, 255, 255, 0.00) 99.98%)`,
      },
      boxShadow: {
        small:
          '0px 0px 32px 0px rgba(223, 83, 255, 0.15), 0px 4px 20px 0px rgba(253, 52, 86, 0.15)',
        medium:
          '0px 0px 32px 0px rgba(223, 83, 255, 0.15), 0px 4px 20px 0px rgba(253, 52, 86, 0.15)',
        large:
          '0px 0px 64px 0px rgba(223, 83, 255, 0.15), 0px 17.73529px 17.73529px 0px rgba(253, 52, 86, 0.15)',
      },
      dropShadow: {
        small: ['0px 0px 32px rgba(223, 83, 255, 0.15)', '0px 4px 20px rgba(253, 52, 86, 0.15)'],
        medium: ['0px 0px 32px rgba(223, 83, 255, 0.15)', '0px 4px 20px rgba(253, 52, 86, 0.15)'],
        large: [
          '0px 0px 64px rgba(223, 83, 255, 0.15)',
          '0px 17.73529px 17.73529px rgba(253, 52, 86, 0.15)',
        ],
      },
    },
    container: {
      center: true,
    },
    screens: {
      // the screens values are going to be used
      // as breakpoints by the 'container'
      sm: '500px',
      md: '768px',
      lg: '1120px',
    },
    fontFamily: {
      saira: ['Saira', 'Saira-Fallback'],
      'maven-pro': ['Maven Pro', 'Maven-Pro-Fallback'],
    },
    colors: {
      ...colors,
      borderColor: {
        DEFAULT: colors.neutrals.n5,
      },
      danger: {
        light: '#ffebee',
        DEFAULT: '#fd3456',
      },
      info: {
        light: colors.neutrals.n2,
        DEFAULT: colors.secondary['s2-blue'],
      },
      success: {
        light: '#ecfcf2',
        DEFAULT: '#3ec92c',
      },
      warning: {
        light: '#fffae7',
        DEFAULT: '#edb800',
      },
      current: 'currentColor',
      grey: {
        DEFAULT: '#e6e6e6',
        light: '#f3f3f3',
        bluish: '#f4f7fb',
        bluishLight: '#f9f9fb',
      },
      link: {
        DEFAULT: colors.secondary['s2-blue'],
        hover: colors.primary.purple,
      },
      search: {
        hit: {
          DEFAULT: '#FFA564',
        },
        tooltip: {
          DEFAULT: '#475760',
        },
      },
      textColor: colors.neutrals.n8,
      white: '#FFFFFF',
    },
    fontSize: {
      xxs: '1.2rem',
      xs: '1.4rem',
      sm: '1.6rem',
      base: '1.8rem',
      m: '2.1rem',
      md: '2.4rem',
      lg: '3.2rem',
      xl: '4.8rem',
      xxl: '5.6rem',
    },
  },
};
