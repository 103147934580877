import { PRODUCTS } from '../constants/global';

const getProductUrlSlug = (product?: string) => {
  switch (product) {
    case PRODUCTS.sonarlint:
      return 'sonarlint';
    case PRODUCTS.sonarcloud:
      return 'sonarcloud';
    case PRODUCTS.sonarqube:
      return 'sonarqube';
    case PRODUCTS.sonarqube_community_build:
      return 'sonarqube-community-build';
    default:
      return '';
  }
};

export default getProductUrlSlug;
