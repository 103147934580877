import {
  Wrapper,
  VersionWrapper,
  ReleaseInfo,
  ReleaseCompatibility,
  ReleaseDate,
  ReleaseDescription,
  LinkContainer,
  DownloadLink,
  DownloadInfo,
} from './SonarScannerVersion.styles';

type Props = {
  versionItem: ScannerVersion;
};

const FALLBACK_DOWNLOAD_LABEL = 'Download';

const SonarScannerVersion = ({ versionItem }: Props) => {
  const { archived, changeLogUrl, compatibility, date, description, downloadURL, version } =
    versionItem;

  function getDownloadurls(downloadUrl: ScannerDownloadUrl): ScannerDownloadUrlItem[] {
    if (!downloadUrl) {
      return [];
    }

    const fallbackLabel = FALLBACK_DOWNLOAD_LABEL;
    const isListOfUrls = typeof downloadUrl !== 'string';

    return isListOfUrls
      ? downloadUrl.map((url: ScannerDownloadUrlItem) => ({
          ...url,
          label: url.label ?? fallbackLabel,
        }))
      : [{ label: fallbackLabel, url: downloadUrl }];
  }

  const downloadUrlList = getDownloadurls(downloadURL);
  const hasDownloadUrls = downloadUrlList.length > 0;
  const hasDownloadLabel = downloadUrlList.some((url) => url.label === FALLBACK_DOWNLOAD_LABEL);

  return (
    <Wrapper>
      <VersionWrapper isArchived={archived}>
        {version}
        {date && <ReleaseDate dateTime={date}>{date}</ReleaseDate>}
      </VersionWrapper>
      <ReleaseInfo>
        {compatibility && <ReleaseCompatibility>{compatibility}</ReleaseCompatibility>}
      </ReleaseInfo>
      {description && <ReleaseDescription>{description}</ReleaseDescription>}
      {hasDownloadUrls && (
        <LinkContainer>
          {!hasDownloadLabel && <DownloadInfo>Download scanner for:</DownloadInfo>}
          {hasDownloadUrls &&
            downloadUrlList.map((advancedDownloadUrl) => (
              <DownloadLink
                href={advancedDownloadUrl.url}
                key={advancedDownloadUrl.url}
                target="_blank"
              >
                {advancedDownloadUrl.label}
              </DownloadLink>
            ))}
        </LinkContainer>
      )}
      {!!changeLogUrl && (
        <LinkContainer>
          <DownloadLink href={changeLogUrl} target="_blank">
            Release notes
          </DownloadLink>
        </LinkContainer>
      )}
    </Wrapper>
  );
};

export default SonarScannerVersion;
