import { ProductItem } from '@hooks/useProducts';
import { StyledImage } from './Logo.styles';
import { PRODUCT_LOGO_WIDTH } from '@constants/global';

const PRODUCT_LOGO_LIST_ITEM_WIDTH = 125;

export interface LogoProps extends ProductItem {
  isListItem?: boolean;
}

const Logo = ({ logo, logoTitle, name: productName, isListItem = false }: LogoProps) => (
  <StyledImage
    alt={logo.value.description || productName}
    loading="eager"
    objectFit="contain"
    objectPosition="left"
    image={{
      height: 40,
      image_id: logo.value.url,
      url: logo.value.url,
      width: isListItem ? PRODUCT_LOGO_LIST_ITEM_WIDTH : PRODUCT_LOGO_WIDTH[productName],
    }}
    title={logoTitle?.value || productName}
  />
);

export default Logo;
