import { RichTextLink } from '@components/RichTextLink/RichTextLink';
import { Element } from 'html-react-parser';

const resolveTableNode = (
  domNode: HTMLTableElement,
  domToReact: Function,
  headerType: Queries.kontent_item_table_elements_header_enum
) => {
  const isHeaderFirstRow = headerType === 'first_row';
  const isHeaderFirstColumn = headerType === 'first_column';

  let columnIndex = 0;
  let rowIndex = 0;

  const options = {
    trim: true,
    replace: (domNode: Element) => {
      switch (domNode.name) {
        case 'a':
          // NOTE Since we 'customize' the table, we need to resolve links contained in it manually
          return <RichTextLink domNode={domNode} />;

        case 'tr':
          rowIndex++;
          // NOTE Reset column index when we move to a new row
          columnIndex = 0;
          break;

        case 'td': {
          columnIndex++;
          // NOTE Render header only for the first row/column
          const shouldRenderHeader =
            (isHeaderFirstRow && rowIndex === 1) || (isHeaderFirstColumn && columnIndex === 1);

          if (shouldRenderHeader) {
            return <th>{domToReact(domNode.children, options)}</th>;
          }
          break;
        }
      }
    },
  };

  return <>{domToReact([domNode], options)}</>;
};

export default resolveTableNode;
