import AnchorHeading from '@components/AnchorHeading/AnchorHeading';
import getTitleFromReactElements from '@utils/getTitleFromReactElements';
import { Element } from 'html-react-parser';
import { RichTextLink } from '@components/RichTextLink/RichTextLink';

const resolveDOMNode = (domNode: Element, domToReact: Function) => {
  if (!domNode.name) {
    return null;
  }

  if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(domNode.name)) {
    const reactElements = domToReact(domNode.children, { trim: true });
    const title = getTitleFromReactElements(reactElements as string | JSX.Element);

    return <AnchorHeading title={title} heading={domNode.name as JSX.Element['type']} />;
  }

  if (domNode.name === 'table') {
    return (
      <div className="table-responsive">
        {domToReact([domNode], {
          trim: true,
          // NOTE Since we 'customize' the table, we need to resolve links contained in it manually
          // Otherwise, they just render as simple <a> tags without processing
          replace: (domNode: Element) => {
            if (domNode.name === 'a') {
              return <RichTextLink domNode={domNode} />;
            }
          },
        })}
      </div>
    );
  }

  if (domNode.name === 'a') {
    return <RichTextLink domNode={domNode} />;
  }
};

export default resolveDOMNode;
