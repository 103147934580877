import { graphql } from 'gatsby';
import { mappedLanguages, ignoredLanguages } from '@utils/hljs/languages.hljs';
import CopyButton from './components/CopyButton';
import { StyledPre, StyledCode } from './CodeSnippet.styles';

const CodeSnippet = ({
  elements: { syntax_highlighting, code_snippet },
}: Queries.CodeSnippetFragment) => {
  let className: string | undefined = 'no-highlight';
  const selectedLanguage = syntax_highlighting?.value;
  if (selectedLanguage) {
    const isMappedLanguage = Object.keys(mappedLanguages).includes(selectedLanguage);
    const isIgnoredLanguage = ignoredLanguages.includes(selectedLanguage);
    if (!isIgnoredLanguage) {
      const language = isMappedLanguage ? mappedLanguages[selectedLanguage] : selectedLanguage;
      className = getClassName(language);
    }
  }
  return (
    <figure>
      <StyledPre>
        <CopyButton value={code_snippet.value} />
        <StyledCode className={className}>{code_snippet.value}</StyledCode>
      </StyledPre>
    </figure>
  );
};

const getClassName = (language?: string) => (language ? `language-${language}` : undefined);

export default CodeSnippet;

export const fragment = graphql`
  fragment CodeSnippet on kontent_item_code_snippet {
    __typename
    elements {
      syntax_highlighting {
        value
      }
      code_snippet {
        value
      }
    }
  }
`;
