import { graphql } from 'gatsby';
import { useState } from 'react';
import { StyledIframe } from './Iframe.styles';

type Props = {
  heightCss?: Queries.IframeFragment['elements']['height_css'];
  title: Queries.IframeFragment['elements']['title']['value'];
  url: Queries.IframeFragment['elements']['url']['value'];
};

const Iframe = ({ heightCss, title, url }: Props) => {
  const [isLoading, setLoading] = useState(true);

  if (!url) {
    return null;
  }

  const stopLoading = () => {
    setLoading(false);
  };

  return (
    <>
      {isLoading && 'Loading...'}
      <StyledIframe
        loading="eager"
        src={url}
        style={{ height: heightCss?.value ? heightCss.value : '70vh' }}
        title={title}
        onLoad={stopLoading}
        onError={stopLoading}
      />
    </>
  );
};

export const fragment = graphql`
  fragment Iframe on kontent_item_iframe {
    __typename
    elements {
      height_css {
        value
      }
      title {
        value
      }
      url {
        value
      }
    }
  }
`;

export default Iframe;
