import RichText from '@components/RichText/RichText';
import { NavigationTextWrapper } from './MenuText.styles';

interface MenuTextProps {
  menuText?: string;
}

const MenuText = ({ menuText }: MenuTextProps) => {
  if (!menuText || menuText === '<p><br></p>') {
    return null;
  }
  return (
    <NavigationTextWrapper>
      <RichText value={menuText} />
    </NavigationTextWrapper>
  );
};

export default MenuText;
