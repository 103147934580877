import { graphql } from 'gatsby';
import { RichTextElement } from '@kontent-ai/gatsby-components';
import slugify from '@utils/slugify';
import { applyPaddingCSS } from '@utils/generatePadding';
import {
  StyledButton,
  StyledDescription,
  StyledEyebrow,
  StyledHeadline,
  StyledContainer,
  StyledSection,
  StyledImageContainer,
  StyledTextContainer,
  StyledImage,
} from './SectionIntroduction.styles';

const SectionIntroduction = ({ elements, system }: Queries.SectionIntroductionFragment) => {
  const {
    subheadline,
    headline,
    description,
    cta,
    theme__color,
    image,
    text_alignment,
    images_options,
    headline_size,
    visibility,
  } = elements;
  const subheadlineValue = subheadline?.value;
  const headlineValue = headline.value;
  const ctaValue = cta?.value;
  const colorValue = theme__color.value;
  const imageOptions = images_options?.value ?? [];
  const isImageAlignLeft = imageOptions.includes('left_aligned');
  const imageHasShadow = imageOptions.includes('shadow');
  const imageValue = image?.value && {
    ...image.value,
    url: image.value.url,
    description: image.value.description ?? '',
    height: image.value.height!,
    width: image.value.width!,
  };

  return (
    <StyledSection
      css={applyPaddingCSS(elements)}
      hideOnMobile={visibility?.value === 'mobile_hidden'}
      data-codename={system.codename}
    >
      <StyledContainer align={text_alignment.value}>
        <StyledTextContainer
          align={text_alignment.value}
          hasImage={Boolean(imageValue)}
          isReversed={isImageAlignLeft}
        >
          {subheadlineValue && <StyledEyebrow color={colorValue}>{subheadlineValue}</StyledEyebrow>}
          <StyledHeadline id={slugify(headlineValue)} size={headline_size.value}>
            {headlineValue}
          </StyledHeadline>
          {description && (
            <StyledDescription>
              <RichTextElement value={description.value} />
            </StyledDescription>
          )}
          {ctaValue && <StyledButton {...ctaValue} variant="primary_persistence_purple" />}
        </StyledTextContainer>
        {imageValue && (
          <StyledImageContainer isReversed={isImageAlignLeft}>
            <StyledImage
              alt={imageValue.description}
              hasShadow={imageHasShadow}
              height={imageValue.height}
              image={{ ...imageValue, image_id: imageValue.url }}
              width={imageValue.width}
            />
          </StyledImageContainer>
        )}
      </StyledContainer>
    </StyledSection>
  );
};
export default SectionIntroduction;

export const fragment = graphql`
  fragment SectionIntroduction on kontent_item_section_introduction {
    __typename
    system {
      codename
    }
    elements {
      ...Padding
      ...Theme
      visibility {
        value
      }
      image {
        value {
          width
          url
          type
          height
          name
          description
        }
      }
      subheadline {
        value
      }
      text_alignment {
        value
      }
      images_options {
        value
      }
      headline {
        value
      }
      headline_size {
        value
      }
      description {
        value
        images {
          description
          height
          url
          image_id
          width
        }
        links {
          link_id
          codename
        }
      }
      cta {
        value {
          ...Link
        }
      }
    }
  }
`;
