import { graphql } from 'gatsby';
import { applyPaddingCSS } from '@utils/generatePadding';
import {
  StyledResponsiveBackgroundImage,
  StyledLink,
  StyledButtonsWrapper,
  StyledContainer,
  StyledHeading,
  StyledSection,
} from './CTABlock.styles';

const CTABlock = ({ CTABlockElements: elements, system }: Queries.CTABlockFragment) => {
  const { background_image, buttons, headline, theme__color, background_options } = elements;
  const isFullWidth = background_options?.value?.includes('fullwidth') ?? false;
  const colorValue = theme__color.value;
  const backgroundImage = background_image?.value && {
    ...background_image.value,
    description: background_image.value.description ?? background_image.value.url,
    image_id: background_image.value.url,
    width: background_image.value.width!,
    height: background_image.value.height!,
  };
  const hasBackgroundImage = !!backgroundImage;

  return (
    <StyledSection
      data-codename={system.codename}
      color={colorValue}
      hasBackgroundImage={hasBackgroundImage}
      isFullWidth={isFullWidth}
      css={applyPaddingCSS(elements)}
    >
      {hasBackgroundImage && isFullWidth && (
        <StyledResponsiveBackgroundImage
          image={backgroundImage}
          alt={backgroundImage.description}
        />
      )}
      <StyledContainer color={colorValue} isFullWidth={isFullWidth}>
        {hasBackgroundImage && !isFullWidth && (
          <StyledResponsiveBackgroundImage
            image={backgroundImage}
            alt={backgroundImage.description}
          />
        )}
        <StyledHeading>{headline.value}</StyledHeading>
        <StyledButtonsWrapper>
          {buttons.value.map((button, index) => (
            <StyledLink {...button} key={button.system.id} variant="primary_persistence_purple" />
          ))}
        </StyledButtonsWrapper>
      </StyledContainer>
    </StyledSection>
  );
};

export default CTABlock;

export const fragment = graphql`
  fragment CTABlock on kontent_item_cta_block {
    __typename
    system {
      codename
    }
    CTABlockElements: elements {
      ...Padding
      ...Theme
      headline {
        value
      }
      background_image {
        value {
          url
          description
          width
          height
        }
      }
      buttons {
        value {
          system {
            id
          }
          ...Link
        }
      }
      background_options {
        value
      }
    }
  }
`;
