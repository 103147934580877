import { graphql, Link } from 'gatsby';
import { ImageElement } from '@kontent-ai/gatsby-components';
import { StyledHeader } from './Header.styles';

const Header = ({ headerElements: { logo } }: Queries.HeaderHomepageFragment) => (
  <StyledHeader>
    <Link to="/" title="Home">
      <ImageElement
        loading="eager"
        image={{
          url: logo.value.url,
          description: logo.value.description ?? '',
          image_id: logo.value.url,
          width: 164,
          height: 26,
        }}
      />
    </Link>
  </StyledHeader>
);

export default Header;

export const fragment = graphql`
  fragment HeaderHomepage on kontent_item_header_homepage {
    __typename
    system {
      codename
    }
    headerElements: elements {
      logo {
        value {
          url
          description
          width
          height
        }
      }
    }
  }
`;
