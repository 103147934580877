import tw, { styled } from 'twin.macro';
import { FooterWrapper } from '../../Footer/Footer.styles';

export const StyledFooter = styled.footer`
  ${tw`container`}

  ${FooterWrapper} {
    ${tw`mx-0`}
  }
`;
