import { Button, ResultLabel, HitsNumber, StyledChevron } from './HitsToggle.styles';

function HitsToggle({
  hitsNumber,
  isVisible,
  handleClick,
}: {
  hitsNumber: number;
  isVisible: boolean;
  handleClick: Function;
}) {
  const resultLabel = (
    <ResultLabel>
      <HitsNumber>{hitsNumber}</HitsNumber> {`result${hitsNumber !== 1 ? 's' : ''}`}
    </ResultLabel>
  );

  if (hitsNumber <= 1) {
    return resultLabel;
  }

  return (
    <Button onClick={() => handleClick()}>
      {resultLabel}
      <StyledChevron open={isVisible} />
    </Button>
  );
}

export default HitsToggle;
