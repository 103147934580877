import { StyledArrow } from './Arrow.styles';

export enum ArrowTheme {
  'header-dropdown',
  'menu-dropdown',
}

export interface ArrowType {
  isOpen: boolean;
  theme?: ArrowTheme;
}

const Arrow = ({ isOpen, theme }: ArrowType) => (
  <StyledArrow isOpen={isOpen} theme={theme} viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="m13.172 12-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
  </StyledArrow>
);

export default Arrow;
