import tw, { styled } from 'twin.macro';
import Link from '@components/Link/Link';

export const BannerCTA = styled(Link)`
  ${tw`pl-[16px]`}
  ${tw`text-xxs text-danger`}
  ${tw`font-bold uppercase`}
`;

export const BannerContent = styled.div`
  ${tw`text-primary-purple`}
  ${tw`font-bold`}
  ${tw`text-xs`}
`;

export const BannerWrapper = styled.div<{
  variant: Queries.kontent_item_banner_elements_variant_enum;
}>`
  ${tw`sticky top-0 left-0 right-0 z-10 `}
  ${tw`py-[16px] px-[40px]`}
    ${tw`flex items-center justify-center`}
    ${tw`border-b border-solid`}
    ${({ variant }) => variant === 'danger' && [tw`bg-danger-light`, tw`border-danger`]}
    ${({ variant }) => variant === 'info' && [tw`bg-info-light`, tw`border-info`]}
    ${({ variant }) => variant === 'success' && [tw`bg-success-light`, tw`border-success`]}
    ${({ variant }) => variant === 'warning' && [tw`bg-warning-light`, tw`border-warning`]}
  
    ${BannerCTA} {
    ${({ variant }) => variant === 'danger' && tw`text-danger`}
    ${({ variant }) => variant === 'info' && tw`text-info`}
      ${({ variant }) => variant === 'success' && tw`text-success`}
      ${({ variant }) => variant === 'warning' && tw`text-info`}
  }
`;
