import { v4 as uuid } from 'uuid';

const USER_TOKEN_KEY = 'userToken';

export default function getAlgoliaUserToken(): string {
  if (typeof window === 'undefined') {
    return '';
  }

  const userTokenFromStorage = localStorage.getItem(USER_TOKEN_KEY) || null;
  const hasUserToken = userTokenFromStorage !== null;

  if (hasUserToken) {
    return userTokenFromStorage;
  }

  const newToken = uuid();
  localStorage.setItem(USER_TOKEN_KEY, newToken);

  return newToken;
}
