import { Configure, InstantSearch } from 'react-instantsearch-hooks-web';
import getAlgoliaUserToken from '@utils/algolia/getAlgoliaUserToken';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { MultipleQueriesQuery } from '@algolia/client-search';
import InsightsMiddleware from './InsightsMiddleware';
import Input from './components/Input/Input';
import Hits from './components/Hits/Hits';

const algoliaAppId = process.env.GATSBY_ALGOLIA_APP_ID ?? '';
const algoliaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY ?? '';
const isPreview = process.env.GATSBY_KONTENT_PREVIEW_MODE === 'true';

// SOURCE https://www.algolia.com/doc/guides/building-search-ui/getting-started/react-hooks/
const algoliaClient = algoliasearch(algoliaAppId, algoliaSearchKey);
const searchClient = {
  ...algoliaClient,
  // NOTE Custom implementation of search() to remove results when no query is typed (!params.query)
  // Otherwise, all results would be displayed even if nothing is typed in the search input.
  search(requests: MultipleQueriesQuery[]) {
    if (requests.every(({ params }) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          processingTimeMS: 0,
          query: '',
        })),
      });
    }

    return algoliaClient.search(requests);
  },
} as SearchClient;

const Search = ({
  productCodename,
  selectedVersionUrlSlug,
}: {
  productCodename?: string;
  selectedVersionUrlSlug?: string;
}) => {
  if (!productCodename) {
    return null;
  }

  const userToken = getAlgoliaUserToken();
  const indexPrefix = isPreview ? 'preview' : 'prod';

  // NOTE Analytics tags are used to filter stats in Algolia
  const analyticsTags = [productCodename];
  const filters = [`productName:"${productCodename}"`];

  if (selectedVersionUrlSlug) {
    analyticsTags.push(`${productCodename}-${selectedVersionUrlSlug}`);
    filters.push(` AND productVersion:"${selectedVersionUrlSlug}"`);
  }

  return (
    <InstantSearch searchClient={searchClient} indexName={`${indexPrefix}_sonar_docs`}>
      <InsightsMiddleware userToken={userToken} />
      <Configure
        attributesToSnippet={['text']}
        clickAnalytics
        analyticsTags={analyticsTags}
        filters={filters.join('')}
        hitsPerPage={20}
        userToken={userToken}
      />
      <Input />
      <Hits />
    </InstantSearch>
  );
};

export default Search;
