import { MenuItemFirstLevel } from '@components/Menu/Menu.styles';
import tw, { styled } from 'twin.macro';

export const CategoryWrapper = styled.li`
  ${tw`w-full`}
`;

export const CategoryButton = styled.button(
  ({ isExpanded, isFirstLevel }: { isExpanded: boolean; isFirstLevel: boolean }) => [
    tw`flex items-center justify-between`,
    tw`w-full`,
    tw`gap-1`,
    tw`font-medium`,
    tw`text-left`,
    tw`hover:text-link-hover hover:bg-white`,
    isExpanded && tw`text-link`,
    !isFirstLevel && tw`pl-5 py-2 text-xs pr-4`,
    isFirstLevel && MenuItemFirstLevel,
  ]
);

export const CategoryItemsWrapper = styled.ul(() => [tw`pl-4`]);
