import { useRef, useState } from 'react';
import useClickOutside from '@hooks/useClickOutside';
import Arrow, { ArrowTheme } from '@components/Arrow/Arrow';
import useProductVersions from '@hooks/useProductVersions';
import { PRODUCTS } from '@constants/global';
import Logo from './components/Logo/Logo';
import {
  VersionLink,
  VersionListItem,
  VersionListItemArchived,
  VersionList,
  VersionDropdownButton,
  Wrapper,
} from './VersionDropdown.styles';

type Props = {
  currentPath: PageContext['baseUrl'];
  productCodename?: string;
  selectedVersionId: string;
};

const VersionDropdown = ({ currentPath, productCodename, selectedVersionId }: Props) => {
  const { getVersionUrl, getVersionById, sortedVersions } = useProductVersions(productCodename);
  const [isOpen, setIsOpen] = useState(false);
  const componentRef = useRef(null);
  const selectedVersion = getVersionById(selectedVersionId);

  useClickOutside(componentRef, () => {
    setIsOpen(false);
  });

  if (!sortedVersions || !selectedVersion) {
    console.log('VersionDropdown: the sorted versions array or the selected version is falsy.');
    return null;
  }

  const versionListItems = sortedVersions.map((version) => {
    const versionLink = getVersionUrl({
      newVersionId: version.id,
      currentPath,
      currentVersionId: selectedVersionId,
    });
    const urlHash = global.window?.location?.hash ? window.location.hash : '';
    const link = `${versionLink}${urlHash}`;

    const ListItem = version.isArchived ? VersionListItemArchived : VersionListItem;

    return (
      <ListItem key={version.id}>
        <VersionLink title={version.name} to={link} onClick={() => setIsOpen(false)}>
          <Logo url={version.logo?.url} description={version.logo?.description || version.name} />
          {version.name}
          {version.isLts && ' LTA'}
        </VersionLink>
      </ListItem>
    );
  });

  return (
    <Wrapper ref={componentRef}>
      <VersionDropdownButton
        aria-label="Display versions"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {productCodename === PRODUCTS.sonarqube && 'Docs '}
        <Logo
          url={selectedVersion.logo?.url}
          description={selectedVersion.logo?.description || selectedVersion.name}
        />
        {selectedVersion.name}
        {selectedVersion.isLts && ' LTA'}
        <Arrow isOpen={isOpen} theme={ArrowTheme['header-dropdown']} />
      </VersionDropdownButton>
      {isOpen && <VersionList>{versionListItems}</VersionList>}
    </Wrapper>
  );
};

export default VersionDropdown;
