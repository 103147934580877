import { ImageElement } from '@kontent-ai/gatsby-components';
import { ImageItem } from '@kontent-ai/gatsby-components/dist/image-element';

const resolveImage = (image?: ImageItem): JSX.Element => {
  if (!image) {
    return <div />;
  }

  return (
    <figure>
      <ImageElement
        image={image}
        loading="eager"
        alt={image.description || undefined}
        height={image.height}
        width={image.width}
      />
    </figure>
  );
};

export default resolveImage;
