import { toast } from 'react-toastify';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { StyledCopyButton, StyledSvg } from '../CodeSnippet.styles';

const CopyButton = ({ value }: { value: string }) => {
  const { copy } = useCopyToClipboard();
  const onClick = () => {
    toast.success('Content was copied to clipboard!');
    copy(value);
  };

  return (
    <StyledCopyButton title="Copy to clipboard" onClick={onClick}>
      <StyledSvg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.5703 0H6.21317C6.09531 0 5.99888 0.0964286 5.99888 0.214286V1.71429C5.99888 1.83214 6.09531 1.92857 6.21317 1.92857H19.4989V20.3571C19.4989 20.475 19.5953 20.5714 19.7132 20.5714H21.2132C21.331 20.5714 21.4275 20.475 21.4275 20.3571V0.857143C21.4275 0.383036 21.0444 0 20.5703 0ZM17.1417 3.42857H3.42746C2.95335 3.42857 2.57031 3.81161 2.57031 4.28571V18.5009C2.57031 18.7286 2.66138 18.9455 2.8221 19.1063L7.46406 23.7482C7.52299 23.8071 7.58996 23.8554 7.66228 23.8955V23.9464H7.77478C7.86853 23.9813 7.96763 24 8.06942 24H17.1417C17.6159 24 17.9989 23.617 17.9989 23.1429V4.28571C17.9989 3.81161 17.6159 3.42857 17.1417 3.42857ZM7.6596 21.2196L5.35335 18.9107H7.6596V21.2196ZM16.0703 22.0714H9.37389V18.2679C9.37389 17.6759 8.89442 17.1964 8.30246 17.1964H4.49888V5.35714H16.0703V22.0714Z"
          fill="currentColor"
        />
      </StyledSvg>
    </StyledCopyButton>
  );
};

export default CopyButton;
