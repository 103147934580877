import tw, { styled } from 'twin.macro';

const navWidth = '362px';

export const MainWrapper = styled.main`
  ${tw`overflow-x-scroll`}
`;

export const NavWrapper = styled.div`
  ${tw`w-[${navWidth}] fixed`};
  ${tw`bg-neutrals-n2`}
`;

export const PageWrapper = styled.div<{
  hasNavigationMenu: boolean;
}>`
  ${tw`relative`};
  ${tw`min-h-screen`}
  ${tw`bg-white`};
  ${tw`pb-8`};
  ${({ hasNavigationMenu }) => hasNavigationMenu && tw`min-w-[740px] ml-[${navWidth}]`}
`;
