import tw, { css, styled } from 'twin.macro';

export const FooterWrapper = styled.div`
  ${tw`border-t-borderColor border-t-[1px]`}
  ${tw`flex`}
  ${tw`ml-24`}
  ${tw`mr-96`}
  ${tw`pt-10`}
  ${tw`mt-10`}
  ${tw`pb-16`}
  ${css`
    p {
      ${tw`text-xs`}
      ${tw`min-w-[500px]`}
    }
    img {
      max-width: initial;
    }
    a {
      ${tw`text-link font-medium no-underline`}
      ${tw`hover:text-link-hover`}

    &.external-link {
        span,
        strong,
        em {
          ${tw`hover:text-link-hover`}
        }

        svg {
          ${tw`fill-neutrals-n7 hover:fill-secondary-s4-purple mb-1`}
          ${tw`w-4`}
        }
      }
    }
  `}
`;

export const IconWrapper = styled.div`
  ${tw`mt-[10px]`}
  ${tw`pl-16`}
`;
