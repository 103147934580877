import 'external-svg-loader';
import { ComponentProps } from 'react';
import { RichTextElement } from '@kontent-ai/gatsby-components';
import { ImageItem } from '@kontent-ai/gatsby-components/dist/image-element';
import { resolveDOMNode, resolveImage, resolveLink, resolveLinkedItem } from '@utils/kontent';

export type RichTextProps = {
  readonly images?: ReadonlyArray<Queries.MakeMaybe<ImageItem, 'height' | 'width'> | null>;
  readonly links?: Readonly<ComponentProps<typeof RichTextElement>['links']>;
  readonly value: Readonly<ComponentProps<typeof RichTextElement>['value']>;
  readonly modular_content?: Readonly<ComponentProps<typeof RichTextElement>['linkedItems']>;
  customResolveDomNode?: Function;
};

const RichText = ({
  images,
  links,
  value,
  modular_content,
  customResolveDomNode,
}: RichTextProps) => {
  if (!value) {
    return null;
  }

  const customResolveImage = (image?: ImageItem | undefined) => {
    if (!image?.width || !image?.height) {
      return <svg {...image} data-src={image?.url} />;
    }

    return resolveImage(image);
  };

  return (
    <RichTextElement
      images={images as ImageItem[]}
      linkedItems={(modular_content as any) || undefined}
      links={(links as any) || undefined}
      resolveDomNode={customResolveDomNode ?? resolveDOMNode}
      resolveImage={customResolveImage}
      resolveLink={resolveLink}
      resolveLinkedItem={resolveLinkedItem}
      value={value}
    />
  );
};

export default RichText;
