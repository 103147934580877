import bash from 'highlight.js/lib/languages/bash';
import c from 'highlight.js/lib/languages/c';
import cobol from 'highlightjs-cobol';
import cplus from 'highlight.js/lib/languages/cpp';
import csharp from 'highlight.js/lib/languages/csharp';
import dockerfile from 'highlight.js/lib/languages/dockerfile';
import groovy from 'highlight.js/lib/languages/groovy';
import ini from 'highlight.js/lib/languages/ini';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import nginxconf from 'highlight.js/lib/languages/nginx';
import objectivec from 'highlight.js/lib/languages/objectivec';
import php from 'highlight.js/lib/languages/php';
import plaintext from 'highlight.js/lib/languages/plaintext';
import properties from 'highlight.js/lib/languages/properties';
import sql from 'highlight.js/lib/languages/sql';
import tcl from 'highlight.js/lib/languages/tcl';
import xml from 'highlight.js/lib/languages/xml';
import yaml from 'highlight.js/lib/languages/yaml';

const languages = {
  bash,
  c,
  cobol,
  cplus,
  csharp,
  dockerfile,
  groovy,
  ini,
  java,
  javascript,
  json,
  nginxconf,
  objectivec,
  php,
  plaintext,
  properties,
  sql,
  tcl,
  xml,
  yaml,
};

export const ignoredLanguages = ['pli', 'csv', 'log', 'httpd_conf'];

export const mappedLanguages: Record<string, string | undefined> = {
  c_: 'csharp',
  c__: 'cplus',
  html: 'xml',
  jenkinsfile: 'groovy',
  nginx_conf: 'nginxconf',
  objective_c: 'objectivec',
  default: undefined,
};

export default languages;
