import tw, { styled } from 'twin.macro';

// create common element here and extend it where needed
export const MenuItemFirstLevel = [
  tw`w-full`,
  tw`text-sm font-medium`,
  tw`px-4 py-2`,
  tw`hover:text-link-hover hover:bg-white`,
];

export const MenuList = styled.ul`
  ${tw`-mx-4`}
  ${tw`pb-8 pt-4`}
`;
