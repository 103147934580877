const getTitleFromReactElements = (el: string | JSX.Element): string => {
  if (typeof el === 'string') {
    return el;
  }

  if (el?.props?.children) {
    return getTitleFromReactElements(el.props.children);
  }

  if (Array.isArray(el)) {
    return el.map((child) => getTitleFromReactElements(child)).join('');
  }

  return '';
};

export default getTitleFromReactElements;
