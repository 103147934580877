import { Chevron } from '@components/Chevron/Chevron.styles';
import tw, { styled } from 'twin.macro';

export const HitsNumber = styled.span(tw`font-medium`);

export const Button = styled.button`
  ${tw`w-full`};
  ${tw`relative`};
  ${tw`text-left`};
`;

export const ResultLabel = styled.span`
  ${tw`w-full`};
  ${tw`text-neutrals-n7 text-xs`};
`;

export const StyledChevron = styled(Chevron)(({ open }: { open: boolean }) => [
  tw`w-6`,
  tw`absolute right-0 top-1`,
  tw`fill-neutrals-n7`,
  open && tw`rotate-0`,
  !open && tw`rotate-180`,
]);
