import { Hit as HitType } from 'instantsearch.js';
import parse from 'html-react-parser';
import { Title, StyledLink, Content } from './Hit.styles';

// NOTE Algolia doesn't generate types based on the custom data we feed it
//
// For instance, we pass 'title' and 'content' in the index
// and we specify with 'attributesToSnippet' property that 'content' has to be "snippeted",
// hence 'content' is located in _snippetResult too
export interface ResultType extends HitType {
  _highlightResult: HitType['_highlightResult'] & {
    text: {
      value: string;
    };
    title: {
      value: string;
    };
  };
  _snippetResult: HitType['_snippetResult'] & {
    text: {
      value: string;
    };
  };
}

function Hit({
  handleResultClick,
  hit,
  url,
}: Readonly<{
  hit: ResultType;
  handleResultClick: Function;
  url: string;
}>) {
  const title = parse(hit._highlightResult.title.value);
  const text = parse(hit._snippetResult.text.value);

  return (
    <StyledLink onClick={() => handleResultClick(hit)} to={url} title={hit.title}>
      <Title>{title}</Title>
      <Content>{text}</Content>
    </StyledLink>
  );
}

export default Hit;
