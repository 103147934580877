import { LATEST_SLUG } from '@constants/global';
import getProductUrlSlug from './getProductUrlSlug';

type Props = {
  internalLinks: Queries.ProductVersionsDataQuery['allKenticoLink']['nodes'];
  latestVersionId?: string;
  productCodename?: string;
  versionId: string;
};

const getHomepageUrlByVersionId = ({
  internalLinks,
  latestVersionId,
  productCodename,
  versionId,
}: Props) => {
  const isLatestVersion = versionId === latestVersionId;
  const productUrlSlug = getProductUrlSlug(productCodename);

  if (isLatestVersion) {
    return `/${productUrlSlug}/${LATEST_SLUG}/`;
  }

  return internalLinks.find((link) => link.isHomepage && link.versionId === versionId)?.url || '/';
};

export default getHomepageUrlByVersionId;
