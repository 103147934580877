import { type SerializedStyles } from '@emotion/react';
import tw, { css } from 'twin.macro';

const blockButtonStyle = css`
  ${tw`inline-block`}
  ${tw`px-10 py-6`}
  ${tw`border-2 border-solid`}
  ${tw`uppercase tracking-[2.1px]`}
  ${tw`font-saira font-bold`}
  ${tw`text-xs text-center`}
  ${tw`whitespace-nowrap`}
  ${tw`overflow-hidden`}
  ${tw`transition-colors duration-300 ease-in-out`}
`;

export type ButtonVariant =
  | 'primary_repeat_red'
  | 'primary_persistence_purple'
  | 'secondary_repeat_red'
  | 'secondary_persistence_purple';

export const variants: Record<ButtonVariant, SerializedStyles> = {
  primary_repeat_red: css`
    ${blockButtonStyle}
    ${tw`text-primary-purple hover:text-primary-red active:text-primary-red`}
    ${tw`border-primary-red active:border-primary-purple`}
    ${tw`bg-primary-red hover:bg-[transparent] active:bg-primary-purple`}
  `,
  primary_persistence_purple: css`
    ${blockButtonStyle}
    ${tw`text-neutrals-n1 hover:text-primary-purple active:text-neutrals-n1`}
    ${tw`border-primary-purple`}
    ${tw`bg-primary-purple hover:bg-[transparent] active:bg-primary-purple`}
  `,
  secondary_repeat_red: css`
    ${blockButtonStyle}
    ${tw`text-primary-purple active:text-primary-red`}
    ${tw`border-primary-red active:border-primary-purple`}
    ${tw`bg-[transparent] hover:bg-primary-red active:bg-primary-purple`}
  `,
  secondary_persistence_purple: css`
    ${blockButtonStyle}
    ${tw`text-primary-purple hover:text-neutrals-n1 active:text-neutrals-n1`}
    ${tw`border-primary-purple`}
    ${tw`bg-[transparent] hover:bg-primary-purple active:bg-primary-purple`}
  `,
};
