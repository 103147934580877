import useProductVersions from '@hooks/useProductVersions';
import Menu from '../Menu/Menu';
import VersionDropdown from '../VersionDropdown/VersionDropdown';
import ProductDropdown from '../ProductDropdown/ProductDropdown';
import MenuFooter from '../MenuFooter/MenuFooter';
import { NavigationWrapper, NavigationHeader, Separator } from './Navigation.styles';
import { MenuItemType } from '@components/Menu/components/MenuItem/MenuItem';
import Search from '@components/Search/Search';
import { RichTextProps } from '@components/RichText/RichText';
import MenuText from '@components/MenuText/MenuText';

const Navigation = ({
  baseUrl,
  productCodename,
  menuFooterContentBody,
  versionId,
}: {
  baseUrl: string;
  productCodename?: string;
  menuFooterContentBody?: RichTextProps;
  versionId: string;
}) => {
  const { getMenuPages, getVersionById, isUsingVersions } = useProductVersions(productCodename);
  const version = getVersionById(versionId);
  const pages = getMenuPages(versionId);
  const hasPages = Boolean(pages);

  return (
    <NavigationWrapper>
      <NavigationHeader>
        <ProductDropdown selectedProduct={productCodename} />
        {isUsingVersions && hasPages && (
          <>
            <Separator />
            <VersionDropdown
              currentPath={baseUrl}
              productCodename={productCodename}
              selectedVersionId={versionId}
            />
          </>
        )}
      </NavigationHeader>
      <Search productCodename={productCodename} selectedVersionUrlSlug={version?.urlSlug} />
      {hasPages && <Menu pages={pages as MenuItemType[]} />}
      <MenuText menuText={version?.menuText} />
      <MenuFooter body={menuFooterContentBody} />
    </NavigationWrapper>
  );
};

export default Navigation;
