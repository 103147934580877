import useGlobalData from '@hooks/useGlobalData';
import { useLocation } from '@reach/router';
import { StyledLink, StyledLinkText } from './MenuLink.styles';

type MenuLinkProps = {
  page: Queries.PageFragment;
  isFirstLevel?: boolean;
};

const MenuLink = ({ page, isFirstLevel = false }: MenuLinkProps) => {
  const { getInternalLink } = useGlobalData();
  const { pathname } = useLocation();
  const url = getInternalLink({ codename: page.system.codename });
  const isActive = url === pathname;

  return (
    <li>
      <StyledLink to={url}>
        <StyledLinkText
          isFirstLevel={isFirstLevel}
          isActive={isActive}
          title={page.elements.title.value}
        >
          {page.elements.short_title.value}
        </StyledLinkText>
      </StyledLink>
    </li>
  );
};

export default MenuLink;
